import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
import Logo from "./images/logo.png";

class VerifyOtp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otp: '',
      formErrotp: '',
      email:localStorage.getItem('registerUserEmail')
    }
    
  }
  handleSubmit = async () => {

    await this.setState({
      formErremail: (this.state.otp === '') ? "Please provide this field" : '',
    })
    if (!(this.state.otp === '')){

      await this.props.verifyOtp({
        email: this.state.email,
        otp: this.state.otp,
      })
      // await window.location.reload(true);

    }
  }

  otpSubmit = async()=>{
    await this.props.resendOtp({
      email: this.state.email,
      from:'Signup'
    })
  }


  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }
  render(){
  return (
    <div className='modalcont'>
      <div className='modalinner'>
        <div className='logo' href='#'>
          <img src={Logo} className='img-fluid' />
        </div>
        <h2>Verify User</h2>

        <div className='loginForm'>
          <div className='mb-3 mt-3'>
            <label htmlFor='otp' className='form-label'>Otp</label>
            <input type='otp' className='form-control form-control-lg' id='email' 
              placeholder='Enter otp'
              value={this.state.otp}
              onChange={(e) => this.onChangeAddName('otp', e.target.value)}
            />
            <span style={{ color: 'red' }}>{this.state.formErrotp}</span>

          </div>
          <div className='d-grid'>
            <button className='btn btn-primary btn-lg' type='button'
            onClick={(e) => this.handleSubmit()}
            >Submit</button>
          </div>

          <div className='d-flex justify-content-between mt-4 mb-4'>
          <button className='btn btn-primary btn-lg' type='button'
            onClick={(e) => this.otpSubmit()}
            >Resend Otp</button>
          </div>
        </div>
      </div>
    </div>
  )
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyOtp: (data) => dispatch(actions.verifyOtp(data)),
    resendOtp:(data)=> dispatch(actions.resendOtp(data))
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(VerifyOtp));
