import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../header";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Orangefr from "../images/orangee.jpg";
import mangofr from "../images/mango.jpg";
import Commentpic from "../images/comments_on_ic.png";
import Imagetwo from "../images/image_two.png";
import ratingpic from "../images/rating_on_ic.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Footer from "../footer";

export default function homepage() {
  return (
    <>
      <Header />

      <section className="mt-5 mb-5">
        <div className="container">
          <div className="shadowCont mt-4">
            <div className="p-4">
              <div>
                <h3>Hours & Operation</h3>
              </div>
              <div className="d-flex">
                <div className=" mt-4">
                  <h4>Monday</h4>
                  <h4>Tuesday</h4>
                  <h4>Wednesday</h4>
                  <h4>Thursday</h4>
                  <h4>Friday</h4>
                  <h4>Saturday</h4>
                  <h4>Sunday</h4>
                </div>
                <div className="mt-4 ml-5 hours">
                  <h4>Closed</h4>
                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>Closed</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="shadowCont mt-4">
            <div className="p-4">
              <h4>Set Location</h4>

              <div class="d-flex align-items-center mb-3 mt-3">
                <div>
                  <h5 style={{ color: "#666" }}>Pick Up Location</h5>
                </div>

                <div
                  className="d-flex justify-content-between align-items-center ml-5"
                  style={{ width: "80%" }}
                >
                  <div class="input-group flex-nowrap">
                    <div
                      class="input-group-prepend"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: "transparent",
                      }}
                    >
                      <span
                        class="input-group-text"
                        id="addon-wrapping"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "transparent",
                        }}
                      >
                        <LocationOnIcon
                          style={{
                            color: "#2dbbab",
                          }}
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div>
                  <h5 style={{ color: "#666" }}>Drop Off Location 1</h5>
                </div>

                <div
                  className="d-flex justify-content-between align-items-center ml-4"
                  style={{ width: "80%" }}
                >
                  <div class="input-group flex-nowrap">
                    <div
                      class="input-group-prepend"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: "transparent",
                      }}
                    >
                      <span
                        class="input-group-text"
                        id="addon-wrapping"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "transparent",
                        }}
                      >
                        <LocationOnIcon
                          style={{
                            color: "#2dbbab",
                          }}
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div>
                  <h5 style={{ color: "#666" }}>Drop Off Location 2</h5>
                </div>

                <div
                  className="d-flex justify-content-between align-items-center ml-4"
                  style={{ width: "80%" }}
                >
                  <div class="input-group flex-nowrap">
                    <div
                      class="input-group-prepend"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: "transparent",
                      }}
                    >
                      <span
                        class="input-group-text"
                        id="addon-wrapping"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "transparent",
                        }}
                      >
                        <LocationOnIcon
                          style={{
                            color: "#2dbbab",
                          }}
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div>
                  <h5 style={{ color: "#666" }}>Drop Off Location 3</h5>
                </div>

                <div
                  className="d-flex justify-content-between align-items-center ml-4"
                  style={{ width: "80%" }}
                >
                  <div class="input-group flex-nowrap">
                    <div
                      class="input-group-prepend"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: "transparent",
                      }}
                    >
                      <span
                        class="input-group-text"
                        id="addon-wrapping"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "transparent",
                        }}
                      >
                        <LocationOnIcon
                          style={{
                            color: "#2dbbab",
                          }}
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button type="button" class="btn btn-delivery-1 mr-4">
                  Ride Now
                </button>
                <button type="button" class="btn btn-delivery-1">
                  Ride Later
                </button>
              </div>
              <div className="mt-4">
                <button type="button" class="btn btn-delivery mr-4">
                  Delivery Now
                </button>
                <button type="button" class="btn btn-delivery">
                  Delivery Later
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
