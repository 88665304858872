import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../header";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Orangefr from "../images/orangee.jpg";
import mangofr from "../images/mango.jpg";
import Commentpic from "../images/comments_on_ic.png";
import Imagetwo from "../images/image_two.png";
import ratingpic from "../images/rating_on_ic.png";
import Footer from "../footer";

export default function homepage() {
  return (
    <>
      <Header />

      <section className="mt-5 mb-5">
        <div className="container">
          <div className="shadowCont mt-4">
            <div className="p-4">
              <div>
                <h3>Hours & Operation</h3>
              </div>
              <div className="d-flex">
                <div className=" mt-4">
                  <h4>Monday</h4>
                  <h4>Tuesday</h4>
                  <h4>Wednesday</h4>
                  <h4>Thursday</h4>
                  <h4>Friday</h4>
                  <h4>Saturday</h4>
                  <h4>Sunday</h4>
                </div>
                <div className="mt-4 ml-5 hours">
                  <h4>Closed</h4>
                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>11:00 am - 10:00 pm</h4>

                  <h4>Closed</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="shadowCont mt-4">
            <div className="p-4">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Salad
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link "
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Combo
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Chicken
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Meat
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Service
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="d-flex align-items-center mt-3">
                    <img src={Orangefr} style={{ width: "250px" }} />

                    <div className="carpoolContent ">
                      <h3>Oranges</h3>
                      <h4>Description plus orange</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal2"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal2"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal4"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal4"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="carpoolImage">
                      <img
                        src={mangofr}
                        style={{ width: "200px", marginLeft: "20px" }}
                      />
                    </div>

                    <div className="carpoolContent ">
                      <h3>Mangoes</h3>
                      <h4>Description plus mangoes</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal3"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal3"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal4"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal4"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="col d-flex justify-content-end ">
                      <div className="col d-flex justify-content-end align-items-center ordrsmry ">
                        <div className="scndcol py-2 px-4">
                          <div className="d-flex ">
                            <p style={{ marginRight: "25px" }}>0 Products </p>
                            <p style={{ marginRight: "25px" }}>|</p>
                            <p>0</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "37px" }}>4 Service </p>

                            <p style={{ marginRight: "25px" }}>|</p>
                            <p style={{ marginRight: "125px" }}>$ 30.00</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "17px" }}>
                              Total Service Time
                            </p>

                            <p style={{ marginRight: "15px" }}>|</p>
                            <p style={{ marginRight: "85px" }}>
                              1 hours 45 mins
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <button type="button" class="btn  btn-order">
                              Order Now
                            </button>
                            <button type="button" class="btn ml-2 btn-order">
                              Order Later
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="d-flex align-items-center mt-3">
                    <img src={Orangefr} style={{ width: "250px" }} />

                    <div className="carpoolContent ">
                      <h3>Oranges</h3>
                      <h4>Description plus orange</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal6"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal6"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal7"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal7"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="carpoolImage">
                      <img
                        src={mangofr}
                        style={{ width: "200px", marginLeft: "20px" }}
                      />
                    </div>

                    <div className="carpoolContent ">
                      <h3>Mangoes</h3>
                      <h4>Description plus mangoes</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal8"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal8"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal9"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal9"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="col d-flex justify-content-end ">
                      <div className="col d-flex justify-content-end align-items-center ordrsmry ">
                        <div className="scndcol py-2 px-4">
                          <div className="d-flex ">
                            <p style={{ marginRight: "25px" }}>0 Products </p>
                            <p style={{ marginRight: "25px" }}>|</p>
                            <p>0</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "37px" }}>4 Service </p>

                            <p style={{ marginRight: "25px" }}>|</p>
                            <p style={{ marginRight: "125px" }}>$ 30.00</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "17px" }}>
                              Total Service Time
                            </p>

                            <p style={{ marginRight: "15px" }}>|</p>
                            <p style={{ marginRight: "85px" }}>
                              1 hours 45 mins
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <button type="button" class="btn  btn-order">
                              Order Now
                            </button>
                            <button type="button" class="btn ml-2 btn-order">
                              Order Later
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="d-flex align-items-center mt-3">
                    <img src={Orangefr} style={{ width: "250px" }} />

                    <div className="carpoolContent ">
                      <h3>Oranges</h3>
                      <h4>Description plus orange</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal11"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal11"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal10"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal10"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="carpoolImage">
                      <img
                        src={mangofr}
                        style={{ width: "200px", marginLeft: "20px" }}
                      />
                    </div>

                    <div className="carpoolContent ">
                      <h3>Mangoes</h3>
                      <h4>Description plus mangoes</h4>
                      <h3>$4.00</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal12"
                      >
                        Add
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal12"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Food Name</h3>
                                </div>
                                <div>
                                  <h3>$4.5</h3>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Complete Meal
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Regular Fries</h5>
                                </div>
                                <div>
                                  <h6>$4.5</h6>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 style={{ color: "green" }}>
                                  Choice of drinks
                                </h5>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Cherry</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Diet Pepsi</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Fruit Punch</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Mountain Dew</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Root Beer</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Shierra Mist</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between modeldiv mt-3">
                                <div>
                                  <h5>Items Total$4.70</h5>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                  <h5>Add Item</h5>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary ml-3"
                        data-toggle="modal"
                        data-target="#exampleModal13"
                      >
                        Size
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal13"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <h3>Select Size</h3>
                                </div>
                              </div>

                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Small</h5>
                                </div>
                                <div>
                                  <h6>$3.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Medium</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <input
                                    type="radio"
                                    aria-label="Radio button for following text input"
                                  />
                                </div>
                                <div
                                  className="mr-2"
                                  style={{ marginRight: "10px" }}
                                >
                                  <h5>Extra Large</h5>
                                </div>
                                <div>
                                  <h6>$0.00</h6>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" class="btn btn-primary">
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div className="col d-flex justify-content-end ">
                      <div className="col d-flex justify-content-end align-items-center ordrsmry ">
                        <div className="scndcol py-2 px-4">
                          <div className="d-flex ">
                            <p style={{ marginRight: "25px" }}>0 Products </p>
                            <p style={{ marginRight: "25px" }}>|</p>
                            <p>0</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "37px" }}>4 Service </p>

                            <p style={{ marginRight: "25px" }}>|</p>
                            <p style={{ marginRight: "125px" }}>$ 30.00</p>
                          </div>
                          <div className="d-flex ">
                            <p style={{ marginRight: "17px" }}>
                              Total Service Time
                            </p>

                            <p style={{ marginRight: "15px" }}>|</p>
                            <p style={{ marginRight: "85px" }}>
                              1 hours 45 mins
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <button type="button" class="btn  btn-order">
                              Order Now
                            </button>
                            <button type="button" class="btn ml-2 btn-order">
                              Order Later
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
