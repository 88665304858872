import React, { useEffect, useState } from "react";
import { connect,useSelector } from "react-redux";
import * as actions from "../store/actions/index";
import { Link, Redirect } from "react-router-dom";
import { ButtonGroup } from "reactstrap";
import PayAndSaveCard from '../cardDetail/PayAndSaveCard';
import EditCard from '../cardDetail/EditCard'
import Calendar from "react-calendar";
import Header from "../header";
import Coupen from "../images/coupon_ic.png";
import Nextic from "../images/next_ic.png";
import "react-calendar/dist/Calendar.css";
import Button from "@material-ui/core/Button";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Footer from "../footer";
import Logo from "../images/logo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarIcon from '@material-ui/icons/Star';
import moment from 'moment-timezone'
toast.configure()

function CarpoolDetail(props) {
  const [value, onChange] = useState(new Date());
  const [notes, handleChange] = useState('')
  const [coupon, onChangeCoupon] = useState('');
  // const [tip,onSubmitTip]=useState(0);
  // const [isTipPercentage, applyPercentage] = useState(false);
  const order = JSON.parse(sessionStorage.getItem('order'));
  const orderStatus = sessionStorage.getItem('now_later');
  const [isTimeUpload, changeTime] = useState(false);
  const [payment_method ,updatePaymentMethod]= useState('')
  // const  {timeSlot}  = useSelector((state) => state);
  
  var dataExtraService = '';
  var dataExtraProduct = '';
  const time_zone = localStorage.getItem('timezone');
  const locality = localStorage.getItem('locality');
  const order_type = sessionStorage.getItem('now_later'); // rideShare || delivery
  const professionalId = localStorage.getItem('professionalId');
  const bussinessId = localStorage.getItem('bussinessId');
  const productDetails = JSON.parse(localStorage.getItem('productDetails'))?JSON.parse(localStorage.getItem('productDetails')):[];
  const serviceDetails = JSON.parse(localStorage.getItem('serviceDetails'))?JSON.parse(localStorage.getItem('serviceDetails')):[];
  const extraProductDetails = localStorage.getItem('extraProductDetails') ? JSON.parse(localStorage.getItem('extraProductDetails')) : [];
  const extraServiceDetails = localStorage.getItem('extraServiceDetails') ? JSON.parse(localStorage.getItem('extraServiceDetails')) : [];
  
  const businessInfo = JSON.parse(localStorage.getItem('getbusiness'));
  const delivery_type = businessInfo.delivery_type;
  const paymentMethod = businessInfo.bussiness_info.paymentMethod
  let currencySymbol = businessInfo ? businessInfo.bussiness_info.currency_symbol : '$';

  const workingSlots = sessionStorage.getItem('timeSlots') ? JSON.parse(sessionStorage.getItem('timeSlots')).workingSlots : [];

  var totalServiceTime = (serviceDetails.reduce((total, obj) => obj.duration + total, 0) + extraServiceDetails.reduce((total, obj) => obj.duration + total, 0))/60

  let dd = moment().tz(time_zone)
  const [reqServiceDate,selectReqDate] = useState(dd);
  const [reqServiceSlot, selectServiceSlot] = useState(dd);
  let cardDetails = props.cardDetails
  let totalServiceWithExtraService = serviceDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0) + extraServiceDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0);
  let totalProductWithExtraProduct = productDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0) + extraProductDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0);
  // console.log(totalProductWithExtraProduct,'------------=-==-=====',productDetails?productDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0):0,extraProductDetails?extraProductDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0):0)
  const onChangeSlot = (e) => {
    // console.log((new Date(e).toISOString() +1).slice(0, 10), 'jjjjjj', moment(e).tz(time_zone).format('YYYY-MM-DD'))

    props.getTimeSlot({
      professionalId: professionalId,
      requestedDate: moment(e).tz(time_zone).format('YYYY-MM-DD'),
      time_zone: time_zone
    })
    onChange(e);
    selectReqDate(moment(e).tz(time_zone));
    selectServiceSlot('')

  }
  const submitSlot = (e)=>{
    // console.log(e,'time kya aa rha hai')
    selectServiceSlot(e)
  }

  const submitCoupon = (e) => {
    if (coupon == '') {
      toast.warning('please Enter Coupon', { autoClose: 1000 })
    }
    onChangeCoupon('')
    props.ApplyCoupon({
      promo_code: coupon,
      orderId: order._id
    })
  }
  const SubmitTip = (value = 0, tipPercentageStatus = false) => {
    if (!value) {
      value = 0
    }
    props.ApplyTipAmount({
      tip: value,
      isTipPercentage: tipPercentageStatus,
      orderId: order._id
    })
  }
  const placeOrder = () =>{
    if(order_type == 'now'){
      if (!payment_method){
        toast.warning('please Select payment method', { autoClose: 1000 })
      }else{
        // if
        var orderData ={
          orderId:order._id,
          req_service_date:reqServiceDate,
          req_service_time:reqServiceSlot,
          notes:notes, 
          expectedServiceTime:totalServiceTime, 
          payment_method:payment_method,
          time_zone:time_zone,
          currency_symbol:currencySymbol
        }
        if(Object.keys(cardDetails).length !== 0){
          orderData.customerId = cardDetails.customerId
        }
        props.orderPaymentByStripe(orderData)
      }
    }else{
      
      if(!reqServiceDate || !reqServiceSlot){
        toast.warning('Please Select Date and Slot', { autoClose: 1000 })
      }else{
        if (!payment_method){
          toast.warning('Please Select payment method', { autoClose: 1000 })
        }else{
          var orderData ={
            orderId:order._id,
            req_service_date:reqServiceDate,
            req_service_time:reqServiceSlot,
            notes:notes, 
            expectedServiceTime:totalServiceTime, 
            payment_method:payment_method,
            time_zone:time_zone,
            currency_symbol:currencySymbol
          }
          if(Object.keys(cardDetails).length !== 0){
            orderData.customerId = cardDetails.customerId
          }
          props.orderPaymentByStripe(orderData)
        }
      }
     
    }
   
  }

  useEffect(()=>{
    props.getCardDetails()
  },[])
 

  return (
    <>
      <Header />
      <section className="mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col shadowCont px-4 py-3">
              {orderStatus == 'later' ?
                <div>
                  <div>
                    <h3>{new Date().toLocaleString('en-us', { month: 'long' })} {new Date().getDate()}</h3>
                  </div>
                  <div>
                    <Calendar onChange={onChangeSlot} />
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between" style={{ width: "480px", overflowX: "scroll" }} >
                    {workingSlots ? workingSlots.map(e => (

                      e.mini_slots.map(et => (
                        <Button variant="outlined" className="timbtn" onClick={(e)=>submitSlot(et.new_time)}>
                          {et.time}
                        </Button>

                      ))
                    )) : ''}

                    {/* <Button variant="outlined" className="timbtnn">
                      11:45 AM
                    </Button>
                    <Button variant="outlined" className="timbtnn">
                      12:45 AM
                    </Button>
                    <Button variant="outlined" className="timbtnn">
                      01:45 PM
                    </Button> */}
                  </div>
                  <hr />
                </div> : ''}
              <div>
                <textarea
                  class="form-control txtdiv"
                  aria-label="With textarea"
                  placeholder="Add notes if any"
                  value={notes}
                  onChange={e => handleChange(e.target.value)}
                ></textarea>
              </div>
              <hr />
              <a
                className="nav-link"
                // href="#"
                data-toggle="modal"
                data-target="#exampleModalforCoupon"
              >

                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <img
                      src={Coupen}
                      style={{ height: "35px", marginRight: "35px" }}
                    />

                    <h4 className="dtlBtmCtn align-items-center mt-2">
                      APPLY COUPON
                    </h4>
                  </div>
                  <div>
                    <img src={Nextic} style={{ height: "25px" }} />
                  </div>

                </div>
              </a>
              <div
                className="modal fade"
                id="exampleModalforCoupon"
                tabIndex="-1"
                aria-labelledby="exampleModalforCoupon"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className='modalcont'>
                        <div className='modalinner'>
                          <div className='logo' href='#'>
                            <img src={Logo} className='img-fluid' />
                          </div>
                          <h2>Apply Coupon</h2>
                          <div className='loginForm'>
                            <div className='mb-3 mt-3'>
                              <input type='text' className='form-control form-control-lg' id='coupon'
                                placeholder='Enter Coupon code here'
                                value={coupon}
                                onChange={e => onChangeCoupon(e.target.value)}
                              />
                            </div>
                            <div className='d-grid'>
                              <button className='btn btn-primary btn-lg' type='button'
                                onClick={(e) => submitCoupon(e)}
                              >Apply Coupon</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h5 style={{ color: "#afaeae" }}>Tip Amount</h5>
                <div className="d-flex tipdiv justify-content-between align-items-center">
                  <ButtonGroup style={{ alignSelf: "stretch" }}>
                    <Button className="btntip" onClick={e => SubmitTip(10, true)} >10%</Button>
                    <Button className="btntip" onClick={e => SubmitTip(20, true)}>20%</Button>
                    <Button className="btntip" onClick={e => SubmitTip(30, true)}>30%</Button>
                  </ButtonGroup>{" "}
                  <input
                    type="number"
                    class="form-control inputtip"
                    placeholder="Enter custom amount."
                    onChange={e => SubmitTip(e.target.value, false)}
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Start Date:- </h6>
                  {/* {console.log(reqServiceSlot,'kaun sa date hai bhai')} */}
                  <h5 className="dtlBtmCtn"> {order ? moment(reqServiceDate).format("dddd, MMMM Do YYYY"): ""}</h5>
                </div>
                {/* <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Finish Date:-</h6>
                  <h5 className="dtlBtmCtn"> 21th April,2021</h5>
                </div> */}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Start Time:- </h6>
                  <h5 className="dtlBtmCtn"> {order ? moment(reqServiceSlot).format("h:mm a"):''}</h5>
                </div>
                {/* <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Finish Time:-</h6>
                  <h5 className="dtlBtmCtn"> 13:00 AM</h5>
                </div> */}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Expected Time Duration:-</h6>
                  <h5 className="dtlBtmCtn"> {totalServiceTime}min</h5>
                </div>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="d-flex align-items-center">
                  <h6 className="dtetmg">Total Distance:-</h6>
                  <h5 className="dtlBtmCtn"> 5 miles</h5>
                </div>
              </div> */}
              {/* <div className="mt-3">
                <h4>Type Your Location</h4>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div class="input-group flex-nowrap">
                    <div
                      class="input-group-prepend"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: "transparent",
                      }}
                    >
                      <span
                        class="input-group-text"
                        id="addon-wrapping"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "transparent",
                        }}
                      >
                        <LocationOnIcon
                          style={{
                            color: "#2dbbab",
                          }}
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
              </div> */}

              <div className="mt-3">
                <h4>Delivery Type</h4>
                {delivery_type.pickup ?
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex mt-2">
                      {" "}
                      <div class="form-check" style={{ marginRight: "15px" }}>
                        <input
                          class="form-check-input position-static"
                          type="radio"
                          name="blankRadio"
                          id="blankRadio1"
                          value="option1"
                          aria-label="..."
                        />{" "}
                      </div>
                      <h4 className="dtlBtmCtn">Pickup</h4>
                    </div>
                  </div>
                  : ''}
                {delivery_type.shipping ?
                  <div className="d-flex mt-2">
                    <div class="form-check  " style={{ marginRight: "15px" }}>
                      <input
                        class="form-check-input position-static"
                        type="radio"
                        name="blankRadio"
                        id="blankRadio1"
                        value="option1"
                        aria-label="..."
                      />{" "}
                    </div>

                    <h4 className="dtlBtmCtn">Shipping </h4>
                  </div>
                  : ''}
                {delivery_type.delivery ?
                  <div className="d-flex mt-2 ">
                    <div class="form-check  " style={{ marginRight: "15px" }}>
                      <input
                        class="form-check-input position-static"
                        type="radio"
                        name="blankRadio"
                        id="blankRadio1"
                        value="option1"
                        aria-label="..."
                      />{" "}
                    </div>
                    <h4 className="dtlBtmCtn">Delivery </h4>
                  </div>
                  : ''}
              </div>
            </div>
            <div className="col-sm-1"></div>
            <div className="col shadowCont px-4 py-3">
              <div>
                <h3>Service</h3>
              </div>

              {serviceDetails ? serviceDetails.map((e) =>
              (
                dataExtraService = extraServiceDetails?extraServiceDetails.filter(x=>x.service_id == e.service_id && x.serviceSize == e.size):'',
                
                <div class="d-flex justify-content-between align-items-center mb-3 mt-3">

                  <h5 style={{ color: "#666" }}> {e.name}
                  {e.size?<p style={{fontSize:'16px'}}>({e.size})</p>:''}
                  <div className="d-flex  align-items-center" style={{marginTop:`${e.size?'-22px':'-10px'}`}}>
                  (
                    { 
                   dataExtraService?dataExtraService.map(et=>{
                     return et?et.sizeArray.map(dd =>{
                       return <p style={{fontSize:'15px' ,marginTop:'15px'}}>{dd.size}</p>
                     })
                     :''
                   })
                   :''
                 
                  })
                 </div>
                  </h5>
                  <h5>{e.quantity}</h5>
                  <h5>{currencySymbol}{
                   
                   dataExtraService.length>0?
                    // console.log(e.totalPrice,'totalllllllllllll',Number(e.totalPrice) + dataExtraService.reduce((a, b) => Number(a) + Number(b.totalPrice), 0))
                     Number(Number(e.totalPrice) + dataExtraService.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)).toFixed(2)
                  
                  :Number(e.totalPrice).toFixed(2)
                  
                  }</h5>
                </div>
              ))
                : ''}


              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Total service</h5>
                <h5>{currencySymbol}{Number(totalServiceWithExtraService).toFixed(2)}</h5>
              </div>
              <hr />
              <div>
                <h3>Product</h3>
              </div>
              {/* <div class="d-flex align-items-center mb-3 mt-3"> */}
                {productDetails ? productDetails.map((e) =>
                (
                  dataExtraProduct = extraProductDetails?extraProductDetails.filter(x=>x.product_id == e.product_id && x.productSize==e.size):'',
                  
                  <div class="d-flex justify-content-between align-items-center mb-3 mt-3" >
                    <h5 style={{ color: "#666" }}>{e.name}
                    {e.size?<p style={{fontSize:'16px'}}> ({e.size})</p>:''}
                     <div className="d-flex  align-items-center" style={{marginTop:`${e.size?'-22px':'-10px'}`}}>(
                      {
                   dataExtraProduct?dataExtraProduct.map(et=>{
                     return et?et.sizeArray.map(dd =>{
                       return <p style={{fontSize:'15px' ,marginTop:'15px'}}>{dd.size} ,</p>
                     })
                     :''
                   })
                   :''
                 }
                    ) </div>
                    </h5>
                    <h5>{e.quantity}</h5>
                    <h5>
                    {currencySymbol}
                    {
                      // console.log(e.totalPrice,'ppppppppppp'),
                      dataExtraProduct.length > 0?
                         Number(Number(e.totalPrice) + dataExtraProduct.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)).toFixed(2)
                      
                      :Number(e.totalPrice).toFixed(2)
                    }
                    </h5>
                    
                    
                  </div>
                ))
                  : ''}
              {/* </div> */}
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Total Product</h5>
                <h5>{currencySymbol}{totalProductWithExtraProduct?Number(totalProductWithExtraProduct).toFixed(2):0}</h5>
              </div>
              <hr />
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Total Product and Service</h5>
                <h5>{currencySymbol}{order ? Number(order.totalProductAndServicePrice).toFixed(2) : ""}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Happy hour Discount</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.happy_hour_discount).toFixed(2) : ""}</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Promo code Discount</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.promocode_Discount).toFixed(2) : ""}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Item Total</h5>
                <h5>{currencySymbol}{order ? Number(order.item_total).toFixed(2) : ""}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Service Tax</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.service_tax).toFixed(2) : ""}</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Product Tax</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.product_tax).toFixed(2) : ""}</h5>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Processing Fees</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order?order.processing_fee:""}</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Service Fees</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order?order.service_fee:""}</h5>
              </div> */}
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Tip Ammount</h5>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.tip_Ammount).toFixed(2) : ""}</h5>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center">
                <h5 className="dtlBtmCtn">Waiting Time</h5>
                <h5 className="dtlBtmCtn">{order?order.waiting_time:""}</h5>
              </div> */}
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <h5>Gross Total</h5>
                <h5>{currencySymbol}{order ? Number(order.grossTotal).toFixed(2) : ""}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <h5 className="dtlBtmCtn mr-2 mt-1">All Service Fee</h5>
                  <a
                    className="nav-link"
                    // href="#"
                    data-toggle="modal"
                    data-target="#exampleModalforStar"
                  > <StarIcon /></a>
                </div>
                <h5 className="dtlBtmCtn">{currencySymbol}{order ? Number(order.all_service_fee).toFixed(2) : ""}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-center ovrttl">
                <h5>Overall Total</h5>
                <h5>{currencySymbol}{order ? Number(order.total_Ammount).toFixed(2) : ""}</h5>
              </div>
              <hr />

              <div>
                <h5>Payment</h5>
                {paymentMethod.Card ?
                 
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {" "}
                      <div
                        class="form-check mt-3"
                        style={{ marginRight: "15px" }}
                      >
                        <input
                          class="form-check-input position-static"
                          type="radio"
                          name="blankRadioCard"
                          id="blankRadioCard"
                          value="optionCard"
                          aria-label="..."
                          // checked={paymentMethod.Card}
                          onChange = {(e)=>updatePaymentMethod('Card')}
                        />{" "}
                      </div>
                      <h4 className="dtlBtmCtn">
                        Card {" "}
                        {/* <span style={{ color: "gray" }}>( Stripe payment )</span>
                        <br />
                        <span style={{ color: "gray" }}>xxxx xxx xxxx 1321</span> */}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {Object.keys(cardDetails).length !== 0  ? 
                      <div>
                       <span style={{ color: "gray" }}>( Stripe payment )</span>
                        <br />
                        <span style={{ color: "gray" }}>xxxx xxx xxxx {cardDetails.last4}</span> 
                        <div>
                          
                      <a
                    className="nav-link"
                    // href="#"
                    data-toggle="modal"
                    data-target="#exampleModalforCardEdit"
                  > 
                      <Button variant="outlined" style={{color: "#2dbbab",border: "2px solid #2dbbab"}}
                      >
                        Edit Card 
                      </Button>
                      </a>
                    </div>
                    </div>
                        :
                        ''
                      }
                    </div>
                    {/* <div>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#2dbbab",

                          border: "2px solid #2dbbab",
                        }}
                      >
                        Edit
                      </Button>
                    </div> */}
                  </div>
                  : ''}
                {paymentMethod.Cash ?
                  <div className="d-flex mt-4">
                    <div class="form-check mt-1 " style={{ marginRight: "15px" }}>
                      <input
                        class="form-check-input position-static"
                        type="radio"
                        name="blankRadio"
                        id="blankRadio1"
                        value="option1"
                        aria-label="..."
                      />{" "}
                    </div>

                    <h4 className="dtlBtmCtn">
                      Cash{" "}
                      <span style={{ color: "gray" }}>( Will nor show up )</span>
                    </h4>
                  </div>
                  : ''}
                {paymentMethod.Cheque ?
                  <div className="d-flex mt-4">
                    <div class="form-check mt-1 " style={{ marginRight: "15px" }}>
                      <input
                        class="form-check-input position-static"
                        type="radio"
                        name="blankRadio"
                        id="blankRadio1"
                        value="option1"
                        aria-label="..."
                      />{" "}
                    </div>
                    <h4 className="dtlBtmCtn">
                      Cheque{" "}
                      <span style={{ color: "gray" }}>( Will nor show up )</span>
                    </h4>
                  </div>
                  : ''}

                <div className="mt-4">
                  <h5 className="dtlBtmCtnn" style={{ lineHeight: "1.7rem" }}>
                    (A Cancellation Fee is assessed for any service cancelled
                    less than 24 hours before the appointment time. No refund
                    will be given if the service is cancelled after the service
                    provider cilck READY TO WORK. Applicable insurance is the
                    responsibility of the service provider.)
                  </h5>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  {Object.keys(cardDetails).length == 0?
                          <a
                          className="nav-link"
                          // href="#"
                          data-toggle="modal"
                          data-target={payment_method?"#exampleModalforPay":"#" }
                          
                        > 
                           <Button variant="outlined"
                           style={{
                            backgroundColor: "#2dbbab",
                            fontWeight: "bold",
                            padding: "15px 70px",
                            border: "2px solid #2dbbab",
                            color: "white",
                            borderRadius: "10px",
                          }}
                            
                           onClick={()=>!payment_method?toast.warning('please Select payment method', { autoClose: 1000 } 
                           ):'' ||order_type=='later'? (!reqServiceDate || !reqServiceSlot)?toast.warning('please Select Date and slot', { autoClose: 1000 }):'':''
                           
                          }
                            >
                             Place Order
                            </Button>
                        </a>
                        :
                        <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "#2dbbab",
                          fontWeight: "bold",
                          padding: "15px 70px",
                          border: "2px solid #2dbbab",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        onClick = {(e)=>{placeOrder()}}
                      >
                        Place Order
                      </Button>
                  }
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* model for service fee star */}
        <div
          className="modal fade"
          id="exampleModalforStar"
          tabIndex="-1"
          aria-labelledby="exampleModalforStar"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className='modalcont'>
                  <div className='modalinner'>
                    <div className='logo' href='#'>
                      <img src={Logo} className='img-fluid' />
                    </div>
                    <h3>Service Fee ?</h3>
                    To help ServiceHubConncet run smoothly and to cover the cost of the product and service ServiceHubConncet provide. We charge a service fee when the order is completed
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*model for pay and save button */}
        <div
          className="modal fade"
          id="exampleModalforPay"
          tabIndex="-1"
          aria-labelledby="exampleModalforStar"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
              <PayAndSaveCard  orderData = { {
                            "orderId":order? order._id:'',
                            "req_service_date": order_type =='later'? reqServiceDate :dd,
                            "req_service_time": order_type =='later'?reqServiceSlot :dd,
                            "notes": notes,
                            "expectedServiceTime": totalServiceTime,
                            "payment_method": payment_method,
                            "time_zone": time_zone,
                            "currency_symbol": currencySymbol
                          }
                          
                          } />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*model for edit card button */}
        <div
          className="modal fade"
          id="exampleModalforCardEdit"
          tabIndex="-1"
          aria-labelledby="exampleModalforStar"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
              <EditCard />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
const mapPropsToState = (state) => {
  return {
    loading: state.order.loading,
    order: state.order.order,
    timeSlot:state.timeS.timeSlot,
    cardDetails:state.cardDetails.cardDetails,
    
  };
};


const mapDispatchToProps = dispatch => {
  return {
    ApplyCoupon: (CouponData) => dispatch(actions.applyCoupon(CouponData)),
    ApplyTipAmount: (tipData) => dispatch(actions.applyTipAmount(tipData)),
    createOrder: (orderData) => dispatch(actions.createOrder(orderData)),
    getTimeSlot: (data) => dispatch(actions.getTimeSlot(data)),
    getCardDetails:()=> dispatch(actions.getCardDetails()),
    orderPaymentByStripe:(data)=>dispatch(actions.orderPaymentByStripe(data))
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(CarpoolDetail);