import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import imagetwo from "../images/image_two.png";
import commentic from "../images/comments_ic.png";
import ratingic from "../images/rating_ic.png";
import checkic from "../images/check_ic.png";
import correctic from "../images/correct.png";
import licenceic from "../images/licence_ic.png";
import insurecic from "../images/insurec_ic.png";
import certificate from "../images/cerrtificate_ic.png";
import axios from "axios";
export default function CarpoolList() {
  const Data = JSON.parse(localStorage.getItem('getlisting'));
  let lat1 = localStorage.getItem('latitude');
  let long1 = localStorage.getItem('longitude');
  let lat2 = localStorage.getItem('latitude1');
  let long2 = localStorage.getItem('longitude1');
  let lat3 = localStorage.getItem('latitude2');
  let long3 = localStorage.getItem('longitude2');
  let lat4 = localStorage.getItem('latitude3');
  let long4 = localStorage.getItem('longitude3');
  console.log(lat1,long1,lat2,long2,'locatiionssss')
  const [distance , updateDistance] = useState(0)
  const [totaltime, updateTime] = useState(0)
//  var distance = 0;
//  var totaltime = 0;
  
  
  if(lat1 && long1 && lat2 && long2){
    axios
    .post(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?origins=${lat1},${long1}&destinations=${lat2},${long2}&key=AIzaSyBALwdShhFbU0EwSx0Zu0UZPtukSK-T5WQ`, {

    })
    .then( async(response)=>{
      let dd = response.data
      let distanceValue =  dd.rows[0].elements[0].distance.value 
      let distanceInMiles =  parseFloat(0.621371 * distanceValue / 1000); //distance in miles
      let timeValue =  dd.rows[0].elements[0].duration.value /60 //in minute
      // console.log(dd.rows[0].elements[0].distance.value,'is there anything',distanceInMiles,timeValue)
      // distance = distanceInMiles
      // totaltime = timeValue
      await updateDistance(distanceInMiles.toFixed(2))
      await updateTime(timeValue.toFixed(2))
               
    }).catch(err=>{
      console.log(err.message,'error kya hai')
    })
  }else if (lat1 && long1 && lat2 && long2 && lat3 && long3){
    axios
    .post(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?origins=${lat1},${long1}&destinations=${lat3},${long3}&key=AIzaSyBALwdShhFbU0EwSx0Zu0UZPtukSK-T5WQ`, {

    })
    .then( async(response)=>{
      let dd = response.data
      let distanceValue =  dd.rows[0].elements[0].distance.value 
      let distanceInMiles =  parseFloat(0.621371 * distanceValue / 1000); //distance in miles
      let timeValue =  dd.rows[0].elements[0].duration.value /60 //in minute
      // console.log(dd.rows[0].elements[0].distance.value,'is there anything',distanceInMiles,timeValue)
      // distance = distanceInMiles
      // totaltime = timeValue
      await updateDistance(distanceInMiles.toFixed(2))
      await updateTime(timeValue.toFixed(2))
               
    }).catch(err=>{
      console.log(err.message,'error kya hai')
    })

  }else if(lat1 && long1 && lat2 && long2 && lat3 && long3 && lat4 & long4){
    axios
    .post(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/distancematrix/json?origins=${lat1},${long1}&destinations=${lat4},${long4}&key=AIzaSyBALwdShhFbU0EwSx0Zu0UZPtukSK-T5WQ`, {

    })
    .then( async(response)=>{
      let dd = response.data
      let distanceValue =  dd.rows[0].elements[0].distance.value 
      let distanceInMiles =  parseFloat(0.621371 * distanceValue / 1000); //distance in miles
      let timeValue =  dd.rows[0].elements[0].duration.value /60 //in minute
      // console.log(dd.rows[0].elements[0].distance.value,'is there anything',distanceInMiles,timeValue)
      // distance = distanceInMiles
      // totaltime = timeValue
      await updateDistance(distanceInMiles.toFixed(2))
      await updateTime(timeValue.toFixed(2))
               
    }).catch(err=>{
      console.log(err.message,'error kya hai')
    })
  }
 
  
      
  return (
    <>
      <Header />

      <section className="mt-5 mb-5">
        <div className="container">
          <div className="shadowCont mt-4">
          <h5>total Distance : {distance} miles </h5>
          <h5>total expected time :{totaltime} min </h5>
          </div>
          
          {Data.map((e) => (
            <div>
              <div className="shadowCont mt-4">
                <div className="px-4">
                  <div className=" d-flex align-items-center">
                    <div className="carpoolImage">
                      <img src={e.profile_pic} />
                      <div className="carpoolImgCtnt">
                        <div>
                          <p>Happy Hours</p>
                          <span>07:05PM, 11:00PM</span>
                        </div>
                        <div className="ratingBox">20%</div>
                      </div>
                    </div>
                    <div
                      className="carpoolContent"
                      //   style={{ borderRight: "1px solid #999", flex: 1 }}
                    >
                      <h3>{e.name}</h3>
                      <p>{e.description}</p>
                      <p>
                        Skills/Talent:- <span>{e.professionalPurpose}</span>
                      </p>
                      <p>
                        City:- <span>{e.local_city}</span>
                      </p>
                      <p>
                        Minimum Cost:- <span>${e.min_cost}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="carpoolContent">
                      <p>
                        <img src={commentic} /> <span>{e.totalComment} Comments</span>
                      </p>
                      <p>
                        <img src={ratingic} /> <span>{e.ratingAverage}</span> ({e.totalRating} Rating)
                      </p>
                      <p>
                        <img src={checkic} /> <span>Background Check</span>{" "}
                        <img src={correctic} /> {e.backgroundCheck}
                      </p>
                      <p>
                        <img src={licenceic} /> <span>Trade Licence</span>{" "}
                        <img src={correctic} /> {e.tradeLicence}
                      </p>

                      <p>
                        <img src={insurecic} /> <span>Insurance</span>{" "}
                        <img src={correctic} /> {e.insured}
                      </p>
                      <p>
                        <img src={certificate} /> <span>Certificate</span>{" "}
                        <img src={correctic} /> {e.certificate_check}
                      </p>
                      <p>
                        <img src={licenceic} />{" "}
                        <span>Driver Licence/State ID</span>{" "}
                        <img src={correctic} /> {e.driver_licence}
                      </p>
                      <p>
                        <img src={insurecic} /> <span>Commercial Insured</span>{" "}
                        <img src={correctic} /> {e.commercial_insurance}
                      </p>
                    </div>
                    <div className ="/productService"><button type="button" class="btn btn-danger">Order Now</button></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </>
  );
}
