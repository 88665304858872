import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "./images/logo.png";
import Login from "./Login";
import Profile from "./profile"
import VerifyOtp from './verifyOtp'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = function (props) {
  const userToken = localStorage.getItem('token')
  const getbusiness = JSON.parse(localStorage.getItem('getbusiness'))
  let logoutData = ['token', 'userInfo', 'userId']
  const saveProfessionIdAndAlert = () => {
    // if (!userToken) {
    //   toast.warning('Please Login to proceed', { autoClose: 1000 })
    // }
    localStorage.setItem("professionalId", props.listing ? props.listing[0]._id : '');
  }

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div>
            <a className="navbar-brand" href="#">
              <img src={getbusiness.business_logo == null ? getbusiness.name : getbusiness.business_logo} style={{ width: "120px" }} />
            </a><p style={{ color: 'white' }}>{getbusiness ? getbusiness.name : ''}</p>


          </div>
          <ul className="navbar-nav ml-auto">
          <li className="nav-item header-nav">
              <a href='/' className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item header-nav">
              <a href='/productService' className="nav-link" onClick={saveProfessionIdAndAlert} >
                Order Now
              </a>
            </li>
            <li className="nav-item header-nav">
            <a href='/productService' className="nav-link" onClick={saveProfessionIdAndAlert} >
                Order Later
              </a>
            </li>
            <li className="nav-item header-nav">
              <a
                className="nav-link"
                // href="#"
                data-toggle="modal"
                data-target="#exampleModal_HoursOfOperation"
              >
                Business Hours
              </a>
            </li>
            <li className="nav-item header-nav">
              <a
                className="nav-link"
                href="#"
                data-toggle="modal"
                data-target="#exampleModal3"
              >About Us</a>


              <div
                className="modal fade"
                id="exampleModal3"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      {/* Simran */}

                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-header border-0">
                              <div className="row align-items-center">
                                <div className="col">
                                  <div className="content"
                                    dangerouslySetInnerHTML=
                                    {{
                                      __html: getbusiness ? getbusiness.about_us : ""
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item header-nav">
              <a className="nav-link" href="/support">
                Contact Us
              </a>
            </li>
            {
              !userToken ?
                <li className="nav-item header-nav">
                  <a
                    className="nav-link"
                    // href="#"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                  >
                    Login
                  </a>
                </li> : <li className="nav-item header-nav">
                  <a
                    className="nav-link"
                    // href="#"
                    data-toggle="modal"
                    data-target="#exampleModal2"
                  > Profile</a>
                </li>
            }

          </ul>
        </nav>

        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Login />
              </div>
              <div className="modal-footer">
                Don't have Account?

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => { window.location.href = '/signup' }}
                // type="button"
                //                 class="btn btn-primary mr-3"
                >
                  SignUp
                </button>


                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <Profile />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  logoutData.forEach(item => localStorage.removeItem(item));
                  window.location.reload(true);
                }}
              >
                Logout
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>




      <div
        className="modal fade"
        id="exampleModal_verify"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <VerifyOtp />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapPropsToState = (state) => {
  return {
    listing: state.professional.listing,
  };
};

export default connect(
  mapPropsToState,

)(withSnackbar(Header));
