import axios from "../../axios-config";
import * as actionTypes from "./actionTypes";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userInfo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userInfo: userInfo,
    error: "Login Successfully"
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const logoutAction = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
export const showAlertNotification = (type, message) => {
  return {
    type: actionTypes.SHOW_ALERT_NOTIFICATION,
    alertType: type,
    alertMessage: message
  };
};
export const auth = (data) => {
  return dispatch => {
    dispatch(authStart());
    console.log(data)
    const authData = {
      email: data.email,
      password: data.password,
      country_code: data.country_code,
      user_type: data.user_type,
    };

    axios
      .post(`/webUser/login`, authData)
      .then(response => {
        if (response.status === 200) {
          let apiResponse = response.data;
          if (apiResponse.status === 0) {
            dispatch(authFail(apiResponse.message));
          } else {
            // console.log(response.data._id);
            
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("userId",response.data.data._id);
            // localStorage.setItem("bussinessName",response.data.data.bussinessName);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            console.log(localStorage.getItem("token"))
            dispatch(
              authSuccess(response.headers.authorization, response.data.data)
            );
            window.location.reload(true);
          }
        }
      })
      .catch(err => {
        console.log(err);
        //dispatch(authFail(err.response.data.error))
      });
  };
};
export const logout = () => {
  return dispatch => {
    let authCode = "Bearer " + localStorage.getItem("token");
      
        // if (response.status === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userId");
          dispatch(logoutAction());
        // }
    
  };
};

export const SignUp = (SignUpdata)=>{
  return dispatch => {
    axios
      .post("/webUser/register", SignUpdata, {

      })
      .then(response => {
        console.log(response,"is this response")
        if (response.status === 200) {
          console.log('whats the response')
          if (response.data.status === 200) {
            localStorage.setItem("registerUserEmail",SignUpdata.email);
            toast.success(response.data.message)
            alert(response.data.message);
            window.location.reload(true);
          } else if (response.data.status === 1){
            localStorage.setItem("registerUserEmail",SignUpdata.email);
            toast.warning(response.data.message)
            // dispatch(authFail(response.data.message))
            alert(response.data.message)
            // window.location.reload(true);
          }
        } else {
          dispatch(authFail(response.message))
          toast.error('Runtime error')
        }
      })
      .catch(err => {
        dispatch(authFail(err.message))
        console.log(err)
        toast.error(err)
      });
  };
  }

  export const verifyOtp = (data) =>{
    return dispatch => {
      axios
        .post("/webUser/verify-user", data, {
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              alert(response.data.message)
              window.location.replace('/')
              
            } else if (response.data.status === 0){
              alert(response.data.message)
              // window.location.reload(true);
            }
          // } else {
          //   dispatch(categoryActionFail(response.message));
          }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
        });
    };
  }


export const resendOtp =(data)=>{
  return dispatch => {
    axios
      .post("/user/resend-otp", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            alert(response.data.message)
            // window.location.replace('/')
            
          } else if (response.data.status === 0){
            alert(response.data.message)
            // window.location.reload(true);
          }
        // } else {
        //   dispatch(categoryActionFail(response.message));
        }
      })
      .catch(err => {
        // dispatch(categoryActionFail(err.message));
        console.log(err)
      });
  };
}