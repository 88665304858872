import "./style/index.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
  
} from "react-router-dom";
import React from "react";
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack'
import homepage from "./homepage/homepage";
import hoursandoperation from "./homepage/hours_operation";
import orderlater from "./homepage/order_later";
import ridesharedelivery from "./rideshare&delivery/rideshare&del";
import servicelocation from "./rideshare&delivery/servicelocation";
import expertrider from "./rideshare&delivery/expertrider";
import TrackProfessional from './homepage/TrackProfessional';
import CarpoolDetail from './homepage/CarpoolDetail';
import Support from './Support';
import SignUp from './SignUp'
const history = createBrowserHistory()
const App = () => {
  return (
    <Router history={history} >
      <SnackbarProvider>
        <div>
          <Switch>
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/support' component={Support} />
            <Route exact path="/productService" component={homepage} />
            <Route exact path="/hoursandoperation" component={hoursandoperation} />
            <Route exact path="/orderlater" component={orderlater} />
            <Route exact path="/ridesharedelivery" component={ridesharedelivery} />
            <Route exact path="/servicelocation" component={servicelocation} />
            <Route exact path="/expertrider" component={expertrider} />
            <Route exact path='/' component={TrackProfessional} />
            <Route exact path='/:subCategoryId' component={TrackProfessional} />
            <Route exact path='/carpooldetail' component={CarpoolDetail} />
            <Route path='/carpooldetail/:id' render={(props) => <CarpoolDetail {...props} />}></Route>
            
            <Route exact path='/:id/:type/:subCategoryId' component={TrackProfessional} />
            
            
          </Switch>
        </div>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
