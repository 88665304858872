import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";

import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../src/style/index.css";

import Header from "./header";
import Footer from "./footer";

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      formErrfirstName: '',
      lastName: '',
      formErrlastName: '',
      email: '',
      formErremail: '',
      password: '',
      formErrpassword: '',
      confirmPassword: '',
      formErrconfirmPassword: '',
      countyCode: '',
      formErrcountyCode: '',
      phoneNumber: '',
      formErrphoneNumber: '',
      localCity: '',
      formErrlocalCity: ''
    }
  }
  handleSubmit = async () => {
    await this.setState({
      formErrfirstName: (this.state.firstName === '') ? "Please provide this field" : '',
      formErrlastName: (this.state.lastName === '') ? "Please provide this field" : '',
      formErrcountyCode: (this.state.countyCode === '') ? "Please provide this field" : '',
      formErrphoneNumber: (this.state.phoneNumber === '') ? "Please provide this field" : '',
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      formErrpassword: (this.state.password == '') ? "Please provide this field" : '',
      formErrconfirmPassword: (this.state.confirmPassword == '') ? "Please provide this field" : '',
      formErrlocalCity: (this.state.localCity == '') ? "Please provide this field" : '',
    })
    if (!((this.state.email === '') && (this.state.password === '') && (this.state.confirmPassword === '') && 
    (this.state.firstName === '') && (this.state.lastName === '') && (this.state.countyCode === '')
     && (this.state.phoneNumber === '') && (this.state.localCity === ''))) {
       if(this.state.password !== this.state.confirmPassword){
         alert('password does not match with confirm password')
       }

      await this.props.SignUp({
        name: this.state.firstName + '' + this.state.lastName,
        country_code: '+' + this.state.countyCode,
        mobile_no: this.state.phoneNumber,
        email: this.state.email,
        password: this.state.password,
        local_city: this.state.localCity
      })
      // await window.location.reload(true);

    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }

  render() {
    console.log(this.state.countyCode, 'phone aaya hai')
    return (
      <>
        <Header />
        <div className="modalcont">
          <div className="modalinner mt-4">
            <h2>SignUp</h2>
            Please enter your personal details

            <div className="loginForm">
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <PersonIcon />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id='firstName'
                  placeholder="Enter First Name"
                  value={this.state.firstName}
                  onChange={(e) => this.onChangeAddName('firstName', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrfirstName}</span>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <PersonIcon />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id='lastName'
                  placeholder="Enter Last Name"
                  value={this.state.lastName}
                  onChange={(e) => this.onChangeAddName('lastName', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrlastName}</span>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <PhoneIcon />
                  </span>
                  <PhoneInput
                    country={"us"}
                    className="marginBottom"
                    id='countyCode'
                    value={this.state.countyCode}
                    onChange={(countyCode) => this.setState({ countyCode })}
                    // onChange={(e) => this.onChangeAddName('countyCode', e.target.value)}
                  />
                  <span style={{ color: 'red' }}>{this.state.formErrcountyCode}</span>
                </div>

                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Enter Mobile Number"
                  id='phoneNumber'
                  value={this.state.phoneNumber}
                  // onChange={(countyCode) => this.setState({ countyCode })}
                  onChange={(e) => this.onChangeAddName('phoneNumber', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrphoneNumber}</span>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <EmailIcon />
                  </span>
                </div>
                <input
                  type="email"
                  class="form-control form-control-lg"
                  placeholder="Enter Email"
                  id='email'
                  value={this.state.email}
                  onChange={(e) => this.onChangeAddName('email', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErremail}</span>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <LockIcon />
                  </span>
                </div>
                <input
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="Enter Password"
                  id='password'
                  value={this.state.password}
                  onChange={(e) => this.onChangeAddName('password', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrpassword}</span>
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <VisibilityIcon />
                  </span>
                </div>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <LockIcon />
                  </span>
                </div>
                <input
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="Enter Confirm Password"
                  id='confirmPassword'
                  value={this.state.confirmPassword}
                  onChange={(e) => this.onChangeAddName('confirmPassword', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrconfirmPassword}</span>
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <VisibilityIcon />
                  </span>
                </div>
              </div>
              <div class="input-group mb-3 mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <SupervisedUserCircleIcon />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Enter Your Local City"
                  id='localCity'
                  value={this.state.localCity}
                  onChange={(e) => this.onChangeAddName('localCity', e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <span style={{ color: 'red' }}>{this.state.formErrlocalCity}</span>
              </div>

              <div className="d-grid mt-5">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="button"
                  onClick={(e) => this.handleSubmit()}
                >
                  SignUp
                </button>
              </div>

              <div className="d-grid mt-3">
                <a
                  className="nav-link"
                  // href="#"
                  data-toggle="modal"
                  data-target="#exampleModal_verify"
                > <button
                  className="btn btn-primary btn-lg "
                  type="button"
                >
                    Verify User
                  </button></a>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>

    )
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    SignUp: (SignUpdata) => dispatch(actions.SignUp(SignUpdata))

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(SignUp));
