import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import * as actions from "../store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

class CarpoolDetail extends React.Component {
  componentWillMount() {
    console.log(this.props.match.params.id)
    let business_id = this.props.match.params.id;
    this.props.fetchprofessional(business_id)
    
  }
  render(){
    return (
      <>
        <Header />
  
        <section className="mt-5 mb-5">
          <div className="container">
            <div className="d-flex mb-3">
              <h3 className="txtBlk">Professional Name</h3>
              <Link class='btn btn-primary float-right' to='/productService'>Product and services</Link>
            </div>
  
            <div className="shadowCont mt-4">
              <div className="p-4">
                <div className="d-flex align-items-center">
                  <div className="carpoolImage">
                    <img src={this.props.data_professional.profile_pic} />
                  </div>
  
                  <div className="carpoolContent">
                    <h3>{this.props.data_professional.name}</h3>
                    <h4>{this.props.data_professional.bussinessName}</h4>
                    <p className="detlRat">
                      <img
                      //   src={require("../../assets/images/rating_on_ic.png")}
                      />{" "}
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
  
                <div className="row">
                  <div className="col">
                    <div className="carpoolContent">
                      <p className="ttl">Open Time</p>
                      <p className="dtlBtmCtn">10:05AM - 12:00PM</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="carpoolContent">
                      <p className="ttl">Desription</p>
                      <p className="dtlBtmCtn">{
                        this.props.data_professional.description
                      }
                      </p>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center">
                    <Link>
                      <p className="cmntLink">
                        <img
                          // src={require("../../assets/images/comments_on_ic.png")}
                        />{" "}
                        <span>2.3K Comments</span>
                        <ChevronRightIcon
                          style={{ width: "40px", height: "40px" }}
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <Footer />
      </>
    ); 
  }
  
}

const mapPropsToState = (state) => {
  return {
    data_professional: state.professional.data_professional,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchprofessional: (data) => dispatch(actions.fetchprofessionalAction(data)),
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(CarpoolDetail));