import axios from "../../axios-config";
import * as actionTypes from "./actionTypes";

export const saveOrderData = (customer,message = '') => {
  return {
    type: actionTypes.SAVE_ORDER_DATA,
    listing: customer,
    message: message ? message : ''
  };
};
export const orderActionFail = message => {
  return {
    type: actionTypes.ORDER_ACTION_FAIL,
    message
  };
}; 
export const saveTimeSlot = (timeSlot,message = '') => {
  return {
    type: actionTypes.SAVE_TIME_SLOT,
    timeSlot: timeSlot,
    message: message ? message : ''
  };
};
export const timeSlotFail = message =>{
  return {
    type: actionTypes.FAIL_TIME_SLOT,
    message
  }
}
export const applyCoupon = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/user/apply-coupon", data, {
            headers: { Authorization: authCode }
        })
        .then(response => {
          console.log(response,'what is response in tip amount')
          if (response.status === 200) {
            if (response.data.status === 200) {
              sessionStorage.setItem("order", response.data.data)
              dispatch(saveOrderData(response.data.data,""));
            } else {
              dispatch(orderActionFail(response.data.message));
            }
          } else {
            dispatch(orderActionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(orderActionFail(err.message));
          console.log(err)
        });
    };
  }

export const applyTipAmount = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/user/apply-tip-ammount", data, {
            headers: { Authorization: authCode }
        })
        .then(response => {
            if (response.status === 200) {
              if (response.data.status === 200) {
                sessionStorage.setItem("order", response.data.data)
                dispatch(saveOrderData(response.data.data,""));
              } else {
                dispatch(orderActionFail(response.data.message));
              }
            } else {
              dispatch(orderActionFail(response.message));
            }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
        });
    };
  }  

export const getTimeSlot = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/user/check-professional-slots", data, {
            headers: { Authorization: authCode }
        })
        .then(response => {
            
          if (response.status === 200) {
            if (response.data.status === 200) {
              localStorage.setItem('workingSlots',JSON.stringify(response.data.data) )
              sessionStorage.setItem("timeSlots", JSON.stringify(response.data.data))
              dispatch(saveTimeSlot(response.data.data,''));
            } else if (response.data.status === 0){
              alert(response.data.message)
              dispatch(timeSlotFail(response.data.message))
            }
          // } else {
          //   dispatch(categoryActionFail(response.message));
          }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
          dispatch(timeSlotFail(err.message))
        });
    };
  }  

export const createOrder = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/user/create-order", data, {
            headers: { Authorization: authCode }
        })
        .then(response => {
            if (response.status === 200) {
              if (response.data.status === 200) {
                sessionStorage.setItem("order", response.data.data)
                sessionStorage.setItem('is_clicked',false)
                dispatch(saveOrderData(response.data.data,""));
              } else {
                dispatch(orderActionFail(response.data.message));
              }
            } else {
              dispatch(orderActionFail(response.message));
            }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
        });
    };
  } 
  export const getCard = (cardDetails,message = '') => {
    return {
      type: actionTypes.GET_CARD_DETAILS,
      cardDetails: cardDetails,
      message: message ? message : ''
    };
  };

  export const updateCard = (cardDetails,message = '') => {
    return {
      type: actionTypes.UPDATE_CARD_DETAILS,
      cardDetails: cardDetails,
      message: message ? message : ''
    };
  };
  export const getCardDetails = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("/user/get-card-details",  {
            headers: { Authorization: authCode }
        })
        .then(response => {
            console.log(response,'is card details')
          if (response.status === 200) {
            if (response.data.status === 200) {
              // localStorage.setItem('workingSlots',JSON.stringify(response.data.data) )
              // sessionStorage.setItem("timeSlots", JSON.stringify(response.data.data))
              dispatch(getCard(response.data.data,''));
            } else if (response.data.status === 0){
              // alert(response.data.message)
              // dispatch(timeSlotFail(response.data.message))
            }
          // } else {
          //   dispatch(categoryActionFail(response.message));
          }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
          // dispatch(timeSlotFail(err.message))
        });
    };
  }  

  export const updateCardDetails = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/user/update-card-details", data,  {
            headers: { Authorization: authCode }
        })
        .then(response => {
            
          if (response.status === 200) {
            if (response.data.status === 200) {
              // localStorage.setItem('workingSlots',JSON.stringify(response.data.data) )
              // sessionStorage.setItem("timeSlots", JSON.stringify(response.data.data))
              dispatch(updateCard(response.data.data,''));
              window.location.reload()
            } else if (response.data.status === 0){
              // alert(response.data.message)
              // dispatch(timeSlotFail(response.data.message))
            }
          // } else {
          //   dispatch(categoryActionFail(response.message));
          }
        })
        .catch(err => {
          // dispatch(categoryActionFail(err.message));
          console.log(err)
          // dispatch(timeSlotFail(err.message))
        });
    };
  } 

  export const placeOrder = (data) =>{
    return dispatch => {
      const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/user/order-placed", data,  {
          headers: { Authorization: authCode }
      })
      .then(response => {
          
        if (response.status === 200) {
          if (response.data.status === 200) {
            alert(response.data.message)
            localStorage.removeItem('timezone')
            localStorage.removeItem('workingSlots');
            localStorage.removeItem('extraServiceDetails');
            localStorage.removeItem('serviceDetails');
            localStorage.removeItem('extraProductDetails');
            localStorage.removeItem('productDetails');
            sessionStorage.clear();
            
            window.location.href='/'
            
          } else if (response.data.status === 0){
            alert(response.data.message);
            
            // dispatch(timeSlotFail(response.data.message))
          }
        // } else {
        //   dispatch(categoryActionFail(response.message));
        }
      })
      .catch(err => {
        // dispatch(categoryActionFail(err.message));
        alert(err)
        console.log(err)
        // dispatch(timeSlotFail(err.message))
      });
  };
  }

  export const orderPaymentByStripe = (orderData) =>{
    console.log(orderData,'orderdata')
    let data ={
      currency_symbol:orderData.currency_symbol, 
      orderId:orderData.orderId, 
      customerId:orderData.customerId?orderData.customerId:'',
      tokenId:orderData.tokenId?orderData.tokenId:'', 
    }
    return dispatch => {
      const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/user/order-payment-by-stripe", data,  {
          headers: { Authorization: authCode }
      })
      .then(response => {
        console.log(response.data,'datttttttttttttt')
        if (response.status === 200) {
          if (response.data.status === 200) {
            let data = {
              orderId:orderData.orderId
              , req_service_date:orderData.req_service_date,
               req_service_time:orderData.req_service_time,
                notes:orderData.notes,
                 expectedServiceTime:orderData.expectedServiceTime,
                  payment_method:orderData.payment_method,
            time_zone:orderData.time_zone
            }
          // console.log(response.data,'datttttttttttttt')
            dispatch(placeOrder(data,""))
            
          } else if (response.data.status === 0){
            alert(response.data.message)
            // dispatch(timeSlotFail(response.data.message))
          }
        // } else {
        //   dispatch(categoryActionFail(response.message));
        }
      })
      .catch(err => {
        // dispatch(categoryActionFail(err.message));
        console.log(err)
        // dispatch(timeSlotFail(err.message))
      });
  };
  }