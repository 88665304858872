import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  cardDetails:{}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CARD_DETAILS:
        // sessionStorage.setItem("timeSlots", JSON.stringify(action.timeSlot))
      return {
        ...state,
        loading:null,
        cardDetails:action.cardDetails,
        error:null,
        success:action.message
      };
      case actionTypes.UPDATE_CARD_DETAILS:
        // sessionStorage.setItem("timeSlots", JSON.stringify(action.timeSlot))
      return {
        ...state,
        loading:null,
        cardDetails:action.cardDetails,
        error:null,
        success:action.message
      };
    case actionTypes.FAIL_TIME_SLOT :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    default:
      return state;
  }
};

export default reducer;
