import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import comment from "../assets/images/comments_ic.png";
import downloadImage from '../assets/images/downloadApps.jpeg';
import downloadPic from '../assets/images/downloadApp2.jpeg';
import rating from "../assets/images/rating_ic.png";
import check from "../assets/images/check_ic.png";
import correct from "../assets/images/correct.png";
import licence from "../assets/images/licence_ic.png";
import insurance from "../assets/images/insurance_ic.png";
import ReactPlayer from 'react-player'
import cerrtificate from "../assets/images/cerrtificate_ic.png";
import insure from "../assets/images/insurec_ic.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// export default function CarpoolList() {
import Map1 from "./map1";
import Map from "./map";
import Map2 from "./map2";
import Map3 from "./map3";
import { Button } from "bootstrap";
import { ContactSupportOutlined } from "@material-ui/icons";
import { hexToRgb } from "@material-ui/core";
import { height } from "@mui/system";
toast.configure()
class CarpoolList extends React.Component {
  constructor(props) {
    super(props);
    // this.link=this.link.bind(this);
    this.state = {
      latitude: "",
      longitude: "",
      time_zone: "",
      location: "",
      rideshare_delivery_type: "",

      latitude1: "",
      longitude1: "",
      location1: "",

      latitude2: "",
      longitude2: "",
      location2: "",

      latitude3: "",
      longitude3: "",
      location3: "",
      data: [],
      type: this.props.match.params.type
        ? parseInt(this.props.match.params.type)
        : 2,
      subCategoryId: "",
      token: localStorage.getItem('token')
    };
    this.rideNow = this.rideNow.bind(this);
  }
  handlelatlong = (value) => {
    // const formvalue = { ...formValues }
    // formvalue.address.x_coordinate = value.latitude
    // formvalue.address.y_coordinate = value.longitude
    // setFormValue(formvalue)

    if (value.key == 1) {
      this.setState({
        latitude1: value.latitude,
        longitude1: value.longitude,
      });
      localStorage.setItem("latitude1", value.latitude);
      localStorage.setItem("longitude1", value.longitude);
    } else if (value.key == 0) {
      this.setState({
        latitude: value.latitude,
        longitude: value.longitude,
      });
      localStorage.setItem("latitude", value.latitude);
      localStorage.setItem("longitude", value.longitude);
    } else if (value.key == 2) {
      this.setState({
        latitude2: value.latitude,
        longitude2: value.longitude,
      });
      localStorage.setItem("latitude2", value.latitude);
      localStorage.setItem("longitude2", value.longitude);
    } else if (value.key == 3) {
      this.setState({
        latitude3: value.latitude,
        longitude3: value.longitude,
      });
      localStorage.setItem("latitude3", value.latitude);
      localStorage.setItem("longitude3", value.longitude);
    }
  };
  handleAddress = (value) => {
    if (value.key == 1) {
      this.setState({
        location1: value.location,
      });
      localStorage.setItem("location1", value.location);
    } else if (value.key == 0) {
      this.setState({
        location: value.location,
      });
      localStorage.setItem("location", value.location);
    } else if (value.key == 2) {
      this.setState({
        location2: value.location,
      });
      localStorage.setItem("location2", value.location);
    } else if (value.key == 3) {
      this.setState({
        location3: value.location,
      });
      localStorage.setItem("location3", value.location);
    }
  };
  handlepincode = (value) => { };
  handlecityname = (value) => { };
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
    });
  };
  saveProfessionIdAndAlert = (Id) => {
    localStorage.setItem("professionalId", Id);
    // if (!this.state.token) {
    //   toast.warning('Please Login to proceed', { autoClose: 1000 })
    // }
  }
  add = () => {
    this.setState({
      type: this.state.type + 1,
    });
  };
  subs = () => {
    this.setState({
      type: this.state.type - 1,
    });
  };
  async componentWillMount() {
    let self = this;
    if (
      self.props != undefined &&
      self.props.match != undefined &&
      self.props.match.params != undefined &&
      self.props.match.params.subCategoryId != undefined
    ) {
      localStorage.setItem(
        "subCategoryId",
        this.props.match.params.subCategoryId
      );
      this.setState({
        subCategoryId: this.props.match.params.subCategoryId,
      });
    } else {
      this.setState({
        subCategoryId: localStorage.getItem("subCategoryId"),
      });
    }
    if (
      self.props != undefined &&
      self.props.match != undefined &&
      self.props.match.params != undefined &&
      self.props.match.params.id != undefined
    ) {
      const rideshare_delivery_type = this.props.match.params.id;

      localStorage.setItem("rideshare_delivery_type", rideshare_delivery_type);
      await this.setState({
        rideshare_delivery_type: rideshare_delivery_type,
      });
      let business_id = "62af76f40486972934427b8c";
      await this.props.getbusinessFromIdAction();
      // await this.props.fetchprofessionalList(business_id)
      await this.props.getDataCategoryAction({
        bussinessId: "62af76f40486972934427b8c",
      });
      const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;
      //Carpool
      await navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem("latitude", position.coords.latitude);
        localStorage.setItem("longitude", position.coords.longitude);
        localStorage.setItem("timezone", timezone);
      });
    } else {
      let business_id = "62af76f40486972934427b8c";
      await this.props.getbusinessFromIdAction();
      await this.props.fetchprofessionalList(business_id);
      await this.props.getDataCategoryAction({
        bussinessId: "62af76f40486972934427b8c",
      });
      const timezone = await Intl.DateTimeFormat().resolvedOptions().timeZone;

      //Carpool
      await navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem("latitude", position.coords.latitude);
        localStorage.setItem("longitude", position.coords.longitude);
        localStorage.setItem("timezone", timezone);
      });
    }
  }
  rideNow = async (e) => {
    console.log("simran");

    const timezone = "America/Chicago"; //localStorage.getItem('timezone')

    if (this.state.type == 1) {
      await this.props.getRideshareProfessional(
        {
          subCategoryId: this.state.subCategoryId,
          latitude1:
            this.state.latitude == ""
              ? parseFloat(localStorage.getItem("latitude"))
              : parseFloat(this.state.latitude),
          longitude1:
            this.state.longitude == ""
              ? parseFloat(localStorage.getItem("longitude"))
              : parseFloat(this.state.longitude),
          rideShare_type: this.state.rideshare_delivery_type,
          time_zone: timezone,
        },
        this.props.history
      );
    } else if (this.state.type == 2) {
      await this.props.getRideshareProfessional(
        {
          subCategoryId: this.state.subCategoryId,
          latitude1:
            this.state.latitude == ""
              ? parseFloat(localStorage.getItem("latitude"))
              : parseFloat(this.state.latitude),
          longitude1:
            this.state.longitude == ""
              ? parseFloat(localStorage.getItem("longitude"))
              : parseFloat(this.state.longitude),
          latitude2:
            this.state.latitude1 == ""
              ? parseFloat(localStorage.getItem("latitude1"))
              : parseFloat(this.state.latitude1),
          longitude2:
            this.state.longitude1 == ""
              ? parseFloat(localStorage.getItem("longitude1"))
              : parseFloat(this.state.longitude1),
          rideShare_type: this.state.rideshare_delivery_type,
          time_zone: timezone,
        },
        this.props.history
      );
    } else if (this.state.type == 3) {
      await this.props.getRideshareProfessional(
        {
          subCategoryId: this.state.subCategoryId,
          latitude1:
            this.state.latitude == ""
              ? parseFloat(localStorage.getItem("latitude"))
              : parseFloat(this.state.latitude),
          longitude1:
            this.state.longitude == ""
              ? parseFloat(localStorage.getItem("longitude"))
              : parseFloat(this.state.longitude),
          latitude2:
            this.state.latitude1 == ""
              ? parseFloat(localStorage.getItem("latitude1"))
              : parseFloat(this.state.latitude1),
          longitude2:
            this.state.longitude1 == ""
              ? parseFloat(localStorage.getItem("longitude1"))
              : parseFloat(this.state.longitude1),
          latitude3:
            this.state.latitude2 == ""
              ? parseFloat(localStorage.getItem("latitude2"))
              : parseFloat(this.state.latitude2),
          longitude3:
            this.state.longitude2 == ""
              ? parseFloat(localStorage.getItem("longitude2"))
              : parseFloat(this.state.longitude2),
          rideShare_type: this.state.rideshare_delivery_type,
          time_zone: timezone,
        },
        this.props.history
      );
    } else if (this.state.type == 4) {
      await this.props.getRideshareProfessional(
        {
          subCategoryId: this.state.subCategoryId,
          latitude1:
            this.state.latitude == ""
              ? parseFloat(localStorage.getItem("latitude"))
              : parseFloat(this.state.latitude),
          longitude1:
            this.state.longitude == ""
              ? parseFloat(localStorage.getItem("longitude"))
              : parseFloat(this.state.longitude),
          latitude2:
            this.state.latitude1 == ""
              ? parseFloat(localStorage.getItem("latitude1"))
              : parseFloat(this.state.latitude1),
          longitude2:
            this.state.longitude1 == ""
              ? parseFloat(localStorage.getItem("longitude1"))
              : parseFloat(this.state.longitude1),
          latitude3:
            this.state.latitude2 == ""
              ? parseFloat(localStorage.getItem("latitude2"))
              : parseFloat(this.state.latitude2),
          longitude3:
            this.state.longitude2 == ""
              ? parseFloat(localStorage.getItem("longitude2"))
              : parseFloat(this.state.longitude2),
          latitude4:
            this.state.latitude3 == ""
              ? parseFloat(localStorage.getItem("latitude3"))
              : parseFloat(this.state.latitude3),
          longitude4:
            this.state.longitude3 == ""
              ? parseFloat(localStorage.getItem("longitude3"))
              : parseFloat(this.state.longitude3),
          rideShare_type: this.state.rideshare_delivery_type,
          time_zone: timezone,
        },
        this.props.history
      );
    }
    console.log(this.props);
  };
  render() {
    const Data = this.props.listing;
    const getbusiness = JSON.parse(localStorage.getItem("getbusiness"));
    const location = localStorage.getItem("location");
    const location1 = localStorage.getItem("location1");
    const location2 = localStorage.getItem("location2");
    const location3 = localStorage.getItem("location3");
    const businessPicture = getbusiness.businessPicture;
    const businessVideo = getbusiness.businessVideo;
    const categoryPicture = getbusiness.categoryPicture

    const rideshare_delivery_type = (
      <div>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={(e) => this.rideNow(e)}
        >
          {this.state.rideshare_delivery_type == "ride_share"
            ? "Ride Now"
            : "Driver Now"}
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          {this.state.rideshare_delivery_type == "ride_share"
            ? "Ride Later"
            : "Driver Later"}
        </button>
      </div>
    );
    let categoryImage =
      categoryPicture ? categoryPicture.map((e) =>
        <div className="col-lg-3">
          <img src={e} width='100%' height='100%' />
        </div>
      )
        : ''

    console.log(this.state.type, "type");
    console.log(this.state.address1, "location");
    return (
      <>
        <Header />
        {this.state.rideshare_delivery_type == "ride_share" ||
          this.state.rideshare_delivery_type == "delivery" ? (
          this.state.type == 2 ? (
            <section>
              <div className="container">
                <div className="shadowCont mt-4">
                  <div className="p-4">
                    <div>
                      <h3>Hours of Operation</h3>
                    </div>
                    <div className="d-flex">
                      <div className=" mt-4">
                        <h4>Monday</h4>
                        <h4>Tuesday</h4>
                        <h4>Wednesday</h4>
                        <h4>Thursday</h4>
                        <h4>Friday</h4>
                        <h4>Saturday</h4>
                        <h4>Sunday</h4>
                      </div>
                      <div className="mt-4 ml-5 hours">
                        <h4>
                          {getbusiness.business_weekdays.is_monday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.monday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.monday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_tuesday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.tuesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_wednesday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.wednesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_thursday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.thursday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.thursday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_friday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.friday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.friday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_saturday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.saturday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.saturday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_sunday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.sunday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.sunday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shadowCont mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className='form-group'> */}
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location1
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location}
                        onChange={(e) =>
                          this.onChangeAddName("location", e.target.value)
                        }
                      />
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location2
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location1}
                        onChange={(e) =>
                          this.onChangeAddName("location1", e.target.value)
                        }
                      />
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={(e) => this.add()}
                      >
                        +
                      </button>
                      {this.state.type}
                      {rideshare_delivery_type}
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                      {/* </div> */}
                      <div
                        style={{
                          height: "500px",
                          width: "500px",
                        }}
                      >
                        <Map1
                          center={{
                            lat: JSON.parse(localStorage.getItem("latitude")),
                            lng: JSON.parse(localStorage.getItem("longitude")),
                          }}
                          handlelatlong={(e) => this.handlelatlong(e)}
                          handleAddress={(e) => this.handleAddress(e)}
                          handlepincode={(e) => this.handlepincode(e)}
                          handlecityname={(e) => this.handlecityname(e)}
                          height="400px"
                          value={location}
                          onChange={(e) =>
                            this.onChangeAddName("location", e.target.value)
                          }
                          zoom={15}
                        />
                      </div>
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : this.state.type == 3 ? (
            <section>
              <div className="container">
                <div className="shadowCont mt-4">
                  <div className="p-4">
                    <div>
                      <h3>Hours of Operation</h3>
                    </div>
                    <div className="d-flex">
                      <div className=" mt-4">
                        <h4>Monday</h4>
                        <h4>Tuesday</h4>
                        <h4>Wednesday</h4>
                        <h4>Thursday</h4>
                        <h4>Friday</h4>
                        <h4>Saturday</h4>
                        <h4>Sunday</h4>
                      </div>
                      <div className="mt-4 ml-5 hours">
                        <h4>
                          {getbusiness.business_weekdays.is_monday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.monday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.monday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_tuesday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.tuesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_wednesday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.wednesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_thursday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.thursday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.thursday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_friday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.friday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.friday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_saturday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.saturday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.saturday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_sunday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.sunday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.sunday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shadowCont mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className='form-group'> */}
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location1
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location}
                        onChange={(e) =>
                          this.onChangeAddName("location", e.target.value)
                        }
                      />
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location2
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location1}
                        onChange={(e) =>
                          this.onChangeAddName("location1", e.target.value)
                        }
                      />

                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location3
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location2}
                        onChange={(e) =>
                          this.onChangeAddName("location2", e.target.value)
                        }
                      />
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={(e) => this.add()}
                      >
                        +
                      </button>
                      {this.state.type}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={(e) => this.subs()}
                      >
                        -
                      </button>
                      {rideshare_delivery_type}
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                      {/* </div> */}
                      <div
                        style={{
                          height: "500px",
                          width: "500px",
                        }}
                      >
                        <Map2
                          center={{
                            lat: JSON.parse(localStorage.getItem("latitude")),
                            lng: JSON.parse(localStorage.getItem("longitude")),
                          }}
                          handlelatlong={(e) => this.handlelatlong(e)}
                          handleAddress={(e) => this.handleAddress(e)}
                          handlepincode={(e) => this.handlepincode(e)}
                          handlecityname={(e) => this.handlecityname(e)}
                          height="400px"
                          value={location2}
                          onChange={(e) =>
                            this.onChangeAddName("location", e.target.value)
                          }
                          zoom={15}
                        />
                      </div>
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section>
              <div className="container">
                <div className="shadowCont mt-4">
                  <div className="p-4">
                    <div>
                      <h3>Hours of Operation</h3>
                    </div>
                    <div className="d-flex">
                      <div className=" mt-4">
                        <h4>Monday</h4>
                        <h4>Tuesday</h4>
                        <h4>Wednesday</h4>
                        <h4>Thursday</h4>
                        <h4>Friday</h4>
                        <h4>Saturday</h4>
                        <h4>Sunday</h4>
                      </div>
                      <div className="mt-4 ml-5 hours">
                        <h4>
                          {getbusiness.business_weekdays.is_monday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.monday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.monday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.monday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.monday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.monday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.monday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_tuesday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.tuesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.tuesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.tuesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.tuesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.tuesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_wednesday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.wednesday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.wednesday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.wednesday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.wednesday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.wednesday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_thursday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.thursday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.thursday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.thursday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.thursday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.thursday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.thursday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_friday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.friday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.friday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.friday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.friday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.friday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.friday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_saturday_Open ==
                            true
                            ? parseInt(
                              getbusiness.business_weekdays.saturday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.saturday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.saturday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.saturday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.saturday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.saturday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>

                        <h4>
                          {getbusiness.business_weekdays.is_sunday_Open == true
                            ? parseInt(
                              getbusiness.business_weekdays.sunday
                                .open_time_hour
                            ) >= 13
                              ? parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) - 12
                              : parseInt(
                                getbusiness.business_weekdays.sunday
                                  .open_time_hour
                              ) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .open_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .open_time_am_pm +
                              " - " +
                              (parseInt(
                                getbusiness.business_weekdays.sunday
                                  .close_time_hour
                              ) >= 13
                                ? parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                ) - 12
                                : parseInt(
                                  getbusiness.business_weekdays.sunday
                                    .close_time_hour
                                )) +
                              ":" +
                              getbusiness.business_weekdays.sunday
                                .close_time_min +
                              " " +
                              getbusiness.business_weekdays.sunday
                                .close_time_am_pm
                            : "Closed"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shadowCont mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div className='form-group'> */}
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location1
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location}
                        onChange={(e) =>
                          this.onChangeAddName("location", e.target.value)
                        }
                      />
                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location2
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location1}
                        onChange={(e) =>
                          this.onChangeAddName("location1", e.target.value)
                        }
                      />

                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location3
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location2}
                        onChange={(e) =>
                          this.onChangeAddName("location2", e.target.value)
                        }
                      />

                      <label
                        className="form-control-label"
                        for="input-first-name"
                      >
                        Users Location4
                      </label>
                      <input
                        type="text"
                        id="input-first-name"
                        className="form-control"
                        placeholder="Users Location"
                        value={location3}
                        onChange={(e) =>
                          this.onChangeAddName("location3", e.target.value)
                        }
                      />
                      {this.state.type}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={(e) => this.subs()}
                      >
                        -
                      </button>
                      {rideshare_delivery_type}
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                      {/* </div> */}
                      <div
                        style={{
                          height: "500px",
                          width: "500px",
                        }}
                      >
                        <Map3
                          center={{
                            lat: JSON.parse(localStorage.getItem("latitude")),
                            lng: JSON.parse(localStorage.getItem("longitude")),
                          }}
                          handlelatlong={(e) => this.handlelatlong(e)}
                          handleAddress={(e) => this.handleAddress(e)}
                          handlepincode={(e) => this.handlepincode(e)}
                          handlecityname={(e) => this.handlecityname(e)}
                          height="400px"
                          value={location3}
                          onChange={(e) =>
                            this.onChangeAddName("location", e.target.value)
                          }
                          zoom={15}
                        />
                      </div>
                      {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        ) : (
          <section className="mt-5 mb-5">

            <div className="container-fluid">
              {/* <button>Hours Of Operation</button> */}

              <div
                className="modal fade"
                id="exampleModal_HoursOfOperation"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      {/*PUT hours of operation code here */}

                      <div className="shadowCont mt-4">
                        <div className="p-4">
                          <div>
                            <h3>Hours of Operation</h3>
                          </div>
                          <div className="d-flex">
                            <div className=" mt-4">
                              <h4>Monday</h4>
                              <h4>Tuesday</h4>
                              <h4>Wednesday</h4>
                              <h4>Thursday</h4>
                              <h4>Friday</h4>
                              <h4>Saturday</h4>
                              <h4>Sunday</h4>
                            </div>
                            <div className="mt-4 ml-5 hours">
                              <h4>
                                {getbusiness.business_weekdays.is_monday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.monday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.monday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.monday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.monday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.monday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.monday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.monday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.monday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.monday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.monday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_tuesday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.tuesday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.tuesday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.tuesday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.tuesday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.tuesday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.tuesday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.tuesday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.tuesday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.tuesday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.tuesday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_wednesday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.wednesday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.wednesday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.wednesday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.wednesday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.wednesday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.wednesday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.wednesday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.wednesday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.wednesday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.wednesday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_thursday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.thursday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.thursday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.thursday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.thursday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.thursday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.thursday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.thursday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.thursday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.thursday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.thursday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_friday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.friday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.friday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.friday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.friday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.friday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.friday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.friday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.friday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.friday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.friday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_saturday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.saturday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.saturday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.saturday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.saturday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.saturday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.saturday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.saturday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.saturday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.saturday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.saturday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>

                              <h4>
                                {getbusiness.business_weekdays.is_sunday_Open == true
                                  ? parseInt(
                                    getbusiness.business_weekdays.sunday
                                      .open_time_hour
                                  ) >= 13
                                    ? parseInt(
                                      getbusiness.business_weekdays.sunday
                                        .open_time_hour
                                    ) - 12
                                    : parseInt(
                                      getbusiness.business_weekdays.sunday
                                        .open_time_hour
                                    ) +
                                    ":" +
                                    getbusiness.business_weekdays.sunday
                                      .open_time_min +
                                    " " +
                                    getbusiness.business_weekdays.sunday
                                      .open_time_am_pm +
                                    " - " +
                                    (parseInt(
                                      getbusiness.business_weekdays.sunday
                                        .close_time_hour
                                    ) >= 13
                                      ? parseInt(
                                        getbusiness.business_weekdays.sunday
                                          .close_time_hour
                                      ) - 12
                                      : parseInt(
                                        getbusiness.business_weekdays.sunday
                                          .close_time_hour
                                      )) +
                                    ":" +
                                    getbusiness.business_weekdays.sunday
                                      .close_time_min +
                                    " " +
                                    getbusiness.business_weekdays.sunday
                                      .close_time_am_pm
                                  : "Closed"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadowCont mt-4">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    <div className="slide-container">

                      <img src={businessPicture[0]} height='500px' width='100%' objectFit='contain'></img>

                      {/* <Slide arrows = {false} duration ={3000}>
      
                        {businessPicture.map((slideImage, index)=>(
                          // console.log(slideImage,'jojoojojojojo',index)
                          <div className="each-slide" key={index}>
                            <div style={{ 
                             height:'500px', width:'200px'}}>
                              <img src={slideImage} ></img>
                            </div>
                          </div>
                        ))}
                      </Slide> */}
                    </div>

                  </div>
                  <div className="col-lg-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={1000} defaultIndex={1}>

                        {businessPicture.length > 1 ? businessPicture.slice(1).map((slideImage, index) => (
                          // console.log(slideImage,'jojoojojojojo',index)
                          <div className="each-slide" key={index}>
                            <div style={{
                              height: '250px', width: '100%',
                            }}>
                              <img src={slideImage} ></img>

                            </div>
                          </div>
                        )) : ''}
                      </Slide>
                    </div>

                    <div className="row no-gutters">
                      <div className="col-lg-6">
                        <div className="slide-container">
                          <Slide arrows={false} defaultIndex={2} duration={1000}>

                            {businessPicture.length > 1 ? businessPicture.slice(1).map((slideImage, index) => (
                              // console.log(slideImage,'jojoojojojojo',index)
                              <div className="each-slide" key={index}>
                                <div style={{
                                  height: '250px', width: '200px',
                                }}>
                                  <img src={slideImage} ></img>

                                </div>
                              </div>
                            )) : ''}
                          </Slide>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="slide-container">
                          <Slide arrows={false} defaultIndex={2} duration={1000}>

                            {businessPicture.length > 1 ? businessPicture.slice(1).map((slideImage, index) => (
                              // console.log(slideImage,'jojoojojojojo',index)
                              <div className="each-slide" key={index}>
                                <div style={{
                                  height: '250px', width: '200px',
                                }}>
                                  <img src={slideImage} ></img>

                                </div>
                              </div>
                            )) : ''}
                          </Slide>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="shadowCont mt-4">
                <div className="row">

                  <div className="col-lg-6">
                    {/* <div className='form-group'> */}
                    <label
                      className="form-control-label"
                      for="input-first-name"
                    >
                      Users Location
                    </label>
                    <input
                      type="text"
                      id="input-first-name"
                      className="form-control"
                      placeholder="Users Location"
                      value={location}
                      onChange={(e) =>
                        this.onChangeAddName("location", e.target.value)
                      }
                    />
                    {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                    {/* </div> */}
                    <div
                      style={{
                        height: "500px",
                        width: "500px",
                      }}
                    >
                      <Map
                        center={{
                          lat: JSON.parse(localStorage.getItem("latitude")),
                          lng: JSON.parse(localStorage.getItem("longitude")),
                        }}
                        handlelatlong={(e) => this.handlelatlong(e)}
                        handleAddress={(e) => this.handleAddress(e)}
                        handlepincode={(e) => this.handlepincode(e)}
                        handlecityname={(e) => this.handlecityname(e)}
                        height="400px"
                        value={location}
                        onChange={(e) =>
                          this.onChangeAddName("location", e.target.value)
                        }
                        zoom={15}
                      />
                    </div>
                    {/* <span style={{ color: 'red' }}>{getbusiness.formErrlocation}</span> */}
                  </div>
                  <div className="col-lg-6">
                    <ReactPlayer url={businessVideo[0]} width='100%' height='500px' controls={true} />
                  </div>
                </div>
              </div>
              

              <div className="shadowCont mt-4">
                <h1 className="text-center my-4" style={{ fontWeight: 'bold', paddingTop: '25px' }}>
                  Browse a Popular Category
                </h1>

                <div className="row">

                  {categoryImage}

                </div>
              </div>

              <div className="shadowCont mt-4 download_apps"
                // style={{ padding: "50px 0 0", backgroundColor: "#4ea690" }}
              >
                <div className="row no-gutters" >
                  <div className="col-md-6">
                    <img src={downloadImage} style={{ width: "100%" }} />
                  </div>
                  <div className="col-md-6">
                    <img src={downloadPic} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>

              {Data.map((e) => (
                <div>
                  <div className="shadowCont mt-4">
                    <div className="row px-4">
                      <div className="col-sm-5 d-flex align-items-center">
                        <div className="carpoolImage">
                          <img src={e.profile_pic} />
                          <div className="carpoolImgCtnt">
                            <div>
                              <p>Happy Hours</p>
                              <span>
                                {e.bussiness_info.happy_hours.start_time},{" "}
                                {e.bussiness_info.happy_hours.end_time}
                              </span>
                            </div>
                            <div className="ratingBox">
                              {e.bussiness_info.happy_hours.discount}%
                            </div>
                          </div>
                        </div>
                        <div
                          className="carpoolContent"
                          style={{ borderRight: "1px solid #999", flex: 1 }}
                        >
                          <h3>{e.name}</h3>
                          <p>{e.professionalPurpose}</p>
                          <p>{e.city}</p>
                          <p>
                            Minimum Cost:- <span>${e.min_cost}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex">
                        <div className="carpoolContent">
                          <p>
                            <img src={comment} />{" "}
                            <span>{e.totalComment} Comments</span>
                          </p>
                          <p>
                            <img src={rating} /> <span>{e.totalRating}</span> (
                            {e.ratingAverage} Rating)
                          </p>

                          <p>
                            <span><i class="fa fa-phone-square" aria-hidden="true" style={{color:"#929292",fontSize:"22px",marginLeft:"6px"}}></i></span>{" "}
                            {e.country_code}{e.mobile_no}
                          </p>
                          <p>
                            <span><i class="fa fa-map-marker" aria-hidden="true" style={{color:"#929292",fontSize:"22px",marginLeft:"6px"}}></i></span>{" "}
                            {getbusiness ? getbusiness.local_city : ''}
                          </p>
                        </div>
                      </div>
                      <div className="col d-flex">
                        <div className="carpoolContent">
                          {/* <p>
                            <img src={insurance} /> <span>Insurance</span>{" "}
                            <img src={correct} /> {e.insured}
                          </p> */}
                          {/* <p>
                            <img src={cerrtificate} /> <span>Certificate</span>{" "}
                            <img src={correct} /> {e.certificate_check}
                          </p> */}
                          {/* <p>
                            <img src={licence} />{" "}
                            <span>Driver Licence/State ID</span>{" "}
                            <img src={correct} /> {e.driver_licence}
                          </p> */}
                          {/* <p>
                            <img src={insure} /> <span>Commercial Insured</span>{" "}
                            <img src={correct} /> {e.commercial_insurance}
                          </p> */}
                          <p>
                            {" "}
                            <Link to='/productService' >
                              <button
                                type="button"
                                class="btn btn-primary mr-3"
                                onClick={() => this.saveProfessionIdAndAlert(e._id)}
                              >
                                Order Now
                              </button>
                            </Link>
                            <Link to='/productService'>
                              <button type="button" class="btn btn-primary"
                                onClick={() => this.saveProfessionIdAndAlert(e._id)}
                              >
                                Order Later
                              </button>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>

          </section>

        )}




        <Footer />
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    listing: state.professional.listing,
    getlisting: state.professional.getlisting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchprofessionalList: (data) =>
      dispatch(actions.fetchprofessionalListAction(data)),
    getDataCategoryAction: (data) => dispatch(actions.getDataCategory(data)),
    getbusinessFromIdAction: () => dispatch(actions.getbusinessFromId()),
    getRideshareProfessional: (data, history) =>
      dispatch(actions.getRideshareProfessionalList(data, history)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(CarpoolList));
