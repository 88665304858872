import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import Logo from "../images/logo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Elements,
  ElementsConsumer,
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");

toast.configure()
class PaymentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    
    this.handleSubmit = async () => {
      const { elements, stripe } = this.props;
      const cardElement = elements.getElement(CardElement);
      let {error,token} = await stripe.createToken(cardElement)
      if(error){
        toast.error(error.message, { autoClose: 1000 })
      }else{
        this.props.updateCardDetails({tokenId:token.id})
  
      }
    };
  }
 
 

  render() {
    return (
      <>
        <CardElement options={{ hidePostalCode: true }}/>
        <button onClick={this.handleSubmit}>Update</button>
      </>
    );
  }
}

class EditCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      card_number:'',
      expir:''
    }
    
  } // live key
  //  = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");
 
 
  render(){
    console.log(this.props,'what is thereeeee','pppppppppppppppppppppp')
  return (
    <div className='modalcont'>
      <div className='modalinner'>
        <div className='logo' href='#'>
          <img src={Logo} className='img-fluid' />
        </div>
        <h2>Edit Your Card Details</h2>
        <Elements stripe={stripePromise} >
        <ElementsConsumer >
          {(ctx) => <PaymentForm {...ctx} updateCardDetails= {this.props.updateCardDetails}/>}
        </ElementsConsumer>
      </Elements>
        {/* <StripeCheckout
        token={this.onToken}
        stripeKey="my_PUBLISHABLE_stripekey"
      /> */}
        {/* <CreditCardInput
  cardNumberInputProps={{  onChange: this.handleCardNumberChange }}
  cardExpiryInputProps={{ onChange: this.handleCardExpiryChange }}
  cardCVCInputProps={{  onChange: this.handleCardCVCChange }}
  fieldClassName="input"
/> */}
      </div>
    </div>
  )
  }
}

const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    // isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCardDetails: (data) => dispatch(actions.updateCardDetails(data))
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(EditCard));
