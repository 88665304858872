import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Header from "../header";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import Orangefr from "../images/orangee.jpg";
import mangofr from "../images/mango.jpg";
import Footer from "../footer";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Spinner from "../Spinner/Spinner";
import ReactDOM from "react-dom";
import moment from 'moment-timezone'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
class homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      business_id: "",
      category_type: "",
      id: "",
      modalData: <div></div>,
      modalData1: <div></div>,
      productWithCategories: [],
      productDetails: [],
      serviceDetails: [],
      subBusinessCategory: JSON.parse(localStorage.getItem('subBusinessCategory')),
      product: [],
      extraProductDetails: [],
      extraServiceDetails: [],
      is_first: true,
      itemTotal: 0,
      productTotal: 0,
      currencySymbol:localStorage.getItem('getbusiness')?JSON.parse(localStorage.getItem('getbusiness')).bussiness_info.currency_symbol:'$',
      token: localStorage.getItem('token')
    }
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.increment_extra = this.increment_extra.bind(this);
    this.onClose = this.onClose.bind(this);
    // this.history = useHistory()
  }
  UNSAFE_componentWillMount = async () => {

    // await this.props.fetchsubBusiness("62af76f40486972934427b8c")
    await this.props.getDataCategoryAction({
      bussinessId: "62af76f40486972934427b8c"
    })
    await this.setState({
      subBusinessCategory: JSON.parse(localStorage.getItem('subBusinessCategory'))
    })
    sessionStorage.clear()
    localStorage.removeItem('extraServiceDetails');
    localStorage.removeItem('serviceDetails')
    localStorage.removeItem('extraProductDetails')
    localStorage.removeItem('productDetails')
  }
  onClose = () => {
    this.setState({
      modalData: <div></div>,
      modalData1: <div></div>,
    })
  }
  async change(id, category_type, info) {
    await this.setState({
      subBusinessCategory: this.props.subBusinessCategory,
      id: id,
      category_type: category_type,
      // product:product,
      is_first: false
    })
  }

  async handleChange(data, type, product_id, index, category_id) {
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == category_id);


    if (check > -1) {
      debugger
      let index_subBusinessCategory = subBusinessCategory[check].info
      console.log(index_subBusinessCategory, 'kya check ho rha hai')
      let check1 = index_subBusinessCategory.findIndex(item => item._id == product_id)

      if (check1 > -1) {
        if (type == "product") {
          const is_check = (!subBusinessCategory[check].info[check1].size_price[index].check) ? true : false
          subBusinessCategory[check].info[check1].size_price[index].check = is_check
          // conditon
          if (is_check) {
            this.increment(subBusinessCategory[check].info[check1], subBusinessCategory[check].info[check1].size_price[index])
          }
          else {
            this.decrement(subBusinessCategory[check].info[check1], subBusinessCategory[check].info[check1].size_price[index])
          }
          // subBusinessCategory[check].info[check1].quantity = is_check?1:0
        }
        else if (type == "service") {
          const is_check = (!subBusinessCategory[check].info[check1].size_price_duration[index].check) ? true : false
          subBusinessCategory[check].info[check1].size_price_duration[index].check = is_check
          // conditon
          if (is_check) {
            this.increment(subBusinessCategory[check].info[check1], subBusinessCategory[check].info[check1].size_price_duration[index])
          }
          else {
            this.decrement(subBusinessCategory[check].info[check1], subBusinessCategory[check].info[check1].size_price_duration[index])
          }
          // subBusinessCategory[check].info[check1].quantity = is_check?1:0

        }

        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
        this.setState({
          subBusinessCategory: subBusinessCategory
        })
      }
    }
  }
  async remove_particular() {

  }
  
  async increment(datas, size_price) {

    if (datas.quantity) {
      datas.quantity++;
    }
    else {
      datas.quantity = 1
    }
    // console.log(JSON.parse(localStorage.getItem('subBusinessCategory')))
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == datas.category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info
      let check1 = index_subBusinessCategory.findIndex(item => item._id == datas._id)
      if (check1 > -1) {
        subBusinessCategory[check].info[check1] = await datas
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
        await this.setState({
          subBusinessCategory: subBusinessCategory
        })
        if (subBusinessCategory[check].category_type == "product") {

          if (size_price.length > 0) {

            let productDetails = [...this.state.productDetails];

            productDetails.push({
              product_id: datas._id,
              quantity: datas.quantity,
              name: datas.name,
              size: size_price.size,
              totalPrice: datas.quantity * parseFloat(size_price.price),
              product_total: datas.quantity * parseFloat(size_price.price),
            })

            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            console.log(productDetails)

            this.setState({
              productDetails: productDetails
            })
            await localStorage.setItem("productDetails", JSON.stringify(productDetails));

            console.log(this.state.productDetails)
          }
          else {
            let productDetails = [...this.state.productDetails];

            let check2 = productDetails.findIndex(item => item.product_id == datas._id)

            if (check2 > -1) productDetails.splice(check2, 1)


            productDetails.push({
              product_id: datas._id,
              quantity: datas.quantity,
              name: datas.name,
              size: "",
              totalPrice: datas.quantity * parseFloat(datas.price),
              product_total: datas.quantity * parseFloat(datas.price),
            })
            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            console.log(productDetails)

            this.setState({
              productDetails: productDetails
            })
            await localStorage.setItem("productDetails", JSON.stringify(productDetails));

            console.log(this.state.productDetails)
          }


        }
        else if (subBusinessCategory[check].category_type == "service") {



          // serviceDetails.push(datas)
          debugger
          if (size_price.length > 0) {
            let serviceDetails = [...this.state.serviceDetails];

            serviceDetails.push({
              service_id: datas._id,
              duration: size_price.duration,
              name: datas.name,
              quantity: datas.quantity,
              size: size_price.size,
              totalPrice: datas.quantity * parseFloat(size_price.price),
              product_total: datas.quantity * parseFloat(size_price.price)
            })
            console.log(serviceDetails)
            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            this.setState({
              serviceDetails: serviceDetails
            })
            await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));

            console.log(this.state.serviceDetails)
          }
          else {
            let serviceDetails = [...this.state.serviceDetails];

            let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)

            if (check2 > -1) serviceDetails.splice(check2, 1)

            serviceDetails.push({
              service_id: datas._id,
              duration: datas.duration,
              name: datas.name,
              quantity: datas.quantity,
              size: "",
              totalPrice: datas.quantity * parseFloat(datas.price),
              product_total: datas.quantity * parseFloat(datas.price)
            })

            console.log(serviceDetails)
            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            this.setState({
              serviceDetails: serviceDetails
            })
            await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));

            console.log(this.state.serviceDetails)
          }

        }

      }
    }

  }

  async decrement(datas, size_price) {
    if (datas.quantity) {
      datas.quantity--;
    }
    else {
      datas.quantity = 0
    }
    // console.log(JSON.parse(localStorage.getItem('subBusinessCategory')))
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == datas.category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info
      let check1 = index_subBusinessCategory.findIndex(item => item._id == datas._id)
      if (check1 > -1) {
        subBusinessCategory[check].info[check1] = await datas
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
        await this.setState({
          subBusinessCategory: subBusinessCategory
        })

        if (subBusinessCategory[check].category_type == "product") {
          let productDetails = [...this.state.productDetails];

          let check2 = productDetails.findIndex(item => item.product_id == datas._id)

          if (check2 > -1) productDetails.splice(check2, 1)


          if (size_price) {
            productDetails.push({
              product_id: datas._id,
              quantity: datas.quantity,
              size: size_price.name,
              name: datas.name,
              totalPrice: datas.quantity * datas.price,
            })
          }
          else {
            productDetails.push({
              product_id: datas._id,
              quantity: datas.quantity,
              size: "",
              name: datas.name,
              totalPrice: datas.quantity * datas.price,
            })
          }

          console.log(productDetails)

          this.setState({
            productDetails: productDetails
          })
          this.setState({
            productTotal: this.state.productTotal - (parseFloat(datas.price))
          })
          await localStorage.setItem("productDetails", JSON.stringify(productDetails));

          console.log(this.state.productDetails)
        }
        else if (subBusinessCategory[check].category_type == "service") {
          let serviceDetails = [...this.state.serviceDetails];

          let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)

          if (check2 > -1) serviceDetails.splice(check2, 1)



          // serviceDetails.push(datas)
          if (size_price) {
            serviceDetails.push({
              service_id: datas._id,
              duration: datas.duration,
              name: datas.name,
              quantity: 1,
              size: size_price,
              totalPrice: datas.price
            })
          } else {
            serviceDetails.push({
              service_id: datas._id,
              duration: datas.duration,
              name: datas.name,
              quantity: 1,
              size: "",
              totalPrice: datas.price
            })
          }

          console.log(serviceDetails)

          this.setState({
            serviceDetails: serviceDetails
          })
          this.setState({
            productTotal: this.state.productTotal - (parseFloat(datas.price))
          })
          await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));

          console.log(this.state.serviceDetails)
        }

      }
    }

  }


  // for extra size =====openModel,increment_extra_size and decrement_extra_size
   async openModel(product_service_data,data, type, _id, category_id, add_data) {
    await this.onClose()
    debugger
    if (type == 'product') {
      let datas = []
      let productDetails = [...this.state.productDetails]
      let getproductDetails;  
      let quantity;
      let check2 = productDetails.findIndex(item => item.service_id == _id)
      let get_datas
      for (let index = 0; index < add_data; index++) {

        get_datas = await data.map(ele => {
          let checkRadio = "checkRadio" + ele.index + _id + index
          for (let index = 0; index < productDetails.length; index++) {
            const element = productDetails[index];
            if(element.product_id == _id){
              if(element.size == ele.size){
                getproductDetails = element.size
                quantity = element.quantity
              }
            }
            
          }
          return <div className="d-flex align-items-center mt-3">

            <input
              type="radio"
              style={{ marginRight: "10px" }}
              name={_id + index}
              id={checkRadio}
              checked={ele.check}
              aria-label="Radio button for following text input"
              // onChange={(e) =>
              //   this.handleChange(data, type, _id, ele.index, category_id)
              // }
            />

            <label
              className="mr-2"
              style={{ marginRight: "10px" }}
              for={checkRadio}
            >
              <h5>{ele.size}</h5>
            </label>
            <div>
              <h6>${ele.price}</h6>
            </div>
            <div>
            {(!productDetails.quantity && (getproductDetails == "" || getproductDetails != ele.size))?
              <div className="quantity-input">
              
                <button className="btn btn-primary ml-3--left"
                    onClick={(e) => {
                      
                      this.increment_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                      
                    }}
                >
                    ADD
                </button>
                </div> :
                <div className="quantity-input">
                <button className="btn btn-primary ml-3--left"
                    onClick={(e) => {
                      
                      this.decrement_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                    }
                      
                    }
                >
                    &mdash;
                </button>
                {quantity}

                <button className="btn btn-primary ml-3--right"
                    onClick={(e) => {
                      
                      this.increment_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                      
                    }}
                >
                    &#xff0b;
                </button>
              </div>
            }
            </div>

          </div>
        })
        datas.push(<div>
          {get_datas}
          {/* <button className="btn btn-primary ml-3--right" 
          onClick={(e)=>this.remove_particular(data,type,_id,category_id,subs)}
          >
            Remove particular
          </button>   */}
        </div>
        )
      }

      await this.setState({
        modalData: datas
      })
    } else if (type == 'service') {
      let datas = []
      debugger
      //for the service with sizes
      let serviceDetails = [...this.state.serviceDetails]
      let getserviceDetails;  
      let quantity;
      let get_datas;
      for (let index = 0; index < add_data; index++) {
        
        get_datas = await data.map( ele => {
          debugger
          let checkRadio = "checkRadio" + ele.index + _id + index
          for (let index = 0; index < serviceDetails.length; index++) {
            const element = serviceDetails[index];
            if(element.service_id == _id){
              if(element.size == ele.size){
                getserviceDetails = element.size
                quantity = element.quantity
              }
            }
            
          }
          return <div className="d-flex align-items-center mt-3">

            <input
              type="radio"
              style={{ marginRight: "10px" }}
              name={_id + index}
              id={checkRadio}
              checked={ele.check}
              aria-label="Radio button for following text input"
              // onChange={(e) =>
              //   this.handleChange(data, type, _id, ele.index, category_id)
              // }
            />
            <label
              className="mr-2"
              style={{ marginRight: "10px" }}
              for={checkRadio}
            >
              <h5>{ele.size}</h5>
            </label>
            <div className="mr-2" style={{ marginRight: "50px" }}>
              <h6>${ele.price}</h6>
            </div>
            <div className="mr-2" style={{ marginRight: "50px" }}>
              <h6>
                {ele.duration / 60} minutes
              </h6>
            </div>
            <div>
            {(!serviceDetails.quantity && (getserviceDetails == "" || getserviceDetails != ele.size))?
              <div className="quantity-input">
                <button className="btn btn-primary ml-3--left"
                    onClick={(e) => {
                      
                      this.increment_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                      }
                    }
                >
                    ADD
                </button>
                </div> :
                <div className="quantity-input">
                <button className="btn btn-primary ml-3--left"
                    onClick={(e) => 
                        {
                          
                          this.decrement_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                        }
                      }
                >
                    &mdash;
                </button>
                {quantity}

                <button className="btn btn-primary ml-3--right"
                    onClick={(e) => {
                        
                        this.increment_extra_size(product_service_data, [ele],data, type, _id, category_id, add_data)
                        
                      }
                    }
                >
                    &#xff0b;
                </button>
              </div>
            }
            </div>
            
          </div>
        })
        datas.push(
          <div>
            {get_datas}
          </div>

        )

      }
      const added = add_data + 1
      const subs = add_data - 1

      
      await this.setState({
        modalData: datas
      })
    }

  }
  async increment_extra_size(datas, size_price,data, type, _id, category_id, add_data) {
    debugger
    if (datas.quantity) {
      datas.quantity++;
    }
    else {
      datas.quantity = 1
    }
    // console.log(JSON.parse(localStorage.getItem('subBusinessCategory')))
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == datas.category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info
      let check1 = index_subBusinessCategory.findIndex(item => item._id == datas._id)
      if (check1 > -1) {
        subBusinessCategory[check].info[check1] = await datas
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
        await this.setState({
          subBusinessCategory: subBusinessCategory
        })
        if (subBusinessCategory[check].category_type == "product") {

          if (size_price.length > 0) {
            let productDetails = [...this.state.productDetails];
            
            let check2 = productDetails.findIndex(item => item.product_id == datas._id)
            if(check2 > -1){
              let is_chek_present = false;
              let i = 0 
              for (let index = 0; index < productDetails.length; index++) {
                const element = productDetails[index];
                if(element.size == size_price[0].size){
                  is_chek_present = true
                  i = index
                }
              }
              if(is_chek_present){
                productDetails[i].quantity = productDetails[i].quantity + 1
                
              }
              else{
                
                productDetails.push({
                  product_id: datas._id,
                  duration: size_price[0].duration,
                  name: datas.name,
                  quantity: 1,
                  size: size_price[0].size,
                  totalPrice:  parseFloat(size_price[0].price),
                  product_total: datas.quantity * parseFloat(size_price[0].price)
                })
              }
              console.log(productDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                productDetails: productDetails
              })
              await localStorage.setItem("productDetails", JSON.stringify(productDetails));
  
              console.log(this.state.productDetails)
            }
            else{
              
              productDetails.push({
                product_id: datas._id,
                duration: size_price[0].duration,
                name: datas.name,
                quantity: 1,
                size: size_price[0].size,
                totalPrice: parseFloat(size_price[0].price),
                product_total: datas.quantity * parseFloat(size_price[0].price)
              })
              console.log(productDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                productDetails: productDetails
              })
              await localStorage.setItem("productDetails", JSON.stringify(productDetails));
  
              console.log(this.state.productDetails)
            }
          }
          else {
            let productDetails = [...this.state.productDetails];

            let check2 = productDetails.findIndex(item => item.product_id == datas._id)

            if (check2 > -1) productDetails.splice(check2, 1)


            productDetails.push({
              product_id: datas._id,
              quantity: datas.quantity,
              name: datas.name,
              size: "",
              totalPrice: parseFloat(datas.price),
              product_total: datas.quantity * parseFloat(datas.price),
            })
            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            console.log(productDetails)

            this.setState({
              productDetails: productDetails
            })
            await localStorage.setItem("productDetails", JSON.stringify(productDetails));

            console.log(this.state.productDetails)
          }


        }
        else if (subBusinessCategory[check].category_type == "service") {

          if (size_price.length > 0) {
            let serviceDetails = [...this.state.serviceDetails];
            
            let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)
            if(check2 > -1){
              let is_chek_present = false;
              let i = 0 
              for (let index = 0; index < serviceDetails.length; index++) {
                const element = serviceDetails[index];
                if(element.size == size_price[0].size){
                  is_chek_present = true
                  i = index
                }
              }
              if(is_chek_present){
                serviceDetails[i].quantity = serviceDetails[i].quantity + 1
                
              }
              else{
                
                serviceDetails.push({
                  service_id: datas._id,
                  duration: size_price[0].duration,
                  name: datas.name,
                  quantity: 1,
                  size: size_price[0].size,
                  totalPrice: parseFloat(size_price[0].price),
                  product_total: datas.quantity * parseFloat(size_price[0].price)
                })
              }
              console.log(serviceDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                serviceDetails: serviceDetails
              })
              await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));
  
              console.log(this.state.serviceDetails)
            }
            else{
              
              serviceDetails.push({
                service_id: datas._id,
                duration: size_price[0].duration,
                name: datas.name,
                quantity: 1,
                size: size_price[0].size,
                totalPrice: parseFloat(size_price[0].price),
                product_total: datas.quantity * parseFloat(size_price[0].price)
              })
              console.log(serviceDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                serviceDetails: serviceDetails
              })
              await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));
  
              console.log(this.state.serviceDetails)
            }
            
          }
          else {
            let serviceDetails = [...this.state.serviceDetails];

            let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)

            if (check2 > -1) serviceDetails.splice(check2, 1)

            serviceDetails.push({
              service_id: datas._id,
              duration: datas.duration,
              name: datas.name,
              quantity: datas.quantity,
              size: "",
              totalPrice:  parseFloat(datas.price),
              product_total: datas.quantity * parseFloat(datas.price)
            })

            console.log(serviceDetails)
            this.setState({
              productTotal: this.state.productTotal + (parseFloat(datas.price))
            })
            this.setState({
              serviceDetails: serviceDetails
            })
            await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));

            console.log(this.state.serviceDetails)
          }

        }
        this.openModel(datas,data, type, _id, category_id, add_data)
      }
    }

  }

  async decrement_extra_size(datas, size_price,data, type, _id, category_id, add_data) {
    if (datas.quantity) {
      datas.quantity--;
    }
    else {
      datas.quantity = 0
    }
    // console.log(JSON.parse(localStorage.getItem('subBusinessCategory')))
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == datas.category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info
      let check1 = index_subBusinessCategory.findIndex(item => item._id == datas._id)
      if (check1 > -1) {
        subBusinessCategory[check].info[check1] = await datas
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
        await this.setState({
          subBusinessCategory: subBusinessCategory
        })

        if (subBusinessCategory[check].category_type == "product") {
          let productDetails = [...this.state.productDetails];

          let check2 = productDetails.findIndex(item => item.product_id == datas._id)

          // if (check2 > -1) productDetails.splice(check2, 1)


          // if (size_price) {
          //   productDetails.push({
          //     product_id: datas._id,
          //     quantity: datas.quantity,
          //     size: size_price.name,
          //     name: datas.name,
          //     totalPrice: datas.quantity * datas.price,
          //   })
          // }
          if (size_price.length > 0) {
            let productDetails = [...this.state.productDetails];
            
            let check2 = productDetails.findIndex(item => item.service_id == datas._id)
            if(check2 > -1){
              let is_chek_present = false;
              let i = 0 
              for (let index = 0; index < productDetails.length; index++) {
                const element = productDetails[index];
                if(element.size == size_price[0].size){
                  is_chek_present = true
                  i = index
                }
              }
              if(is_chek_present){
                productDetails[i].quantity = productDetails[i].quantity - 1
                if(productDetails[i].quantity == 0){
                  productDetails.splice(i, 1)
                }
              }
              console.log(productDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                productDetails: productDetails
              })
              await localStorage.setItem("productDetails", JSON.stringify(productDetails));
  
              console.log(this.state.productDetails)
            }
            // else{
              
            //   productDetails.push({
            //     service_id: datas._id,
            //     duration: size_price[0].duration,
            //     name: datas.name,
            //     quantity: 1,
            //     size: size_price[0].size,
            //     totalPrice: size_price[0].price,
            //     product_total: datas.quantity * parseFloat(size_price[0].price)
            //   })
            //   console.log(productDetails)
            //   this.setState({
            //     productTotal: this.state.productTotal + (parseFloat(datas.price))
            //   })
            //   this.setState({
            //     productDetails: productDetails
            //   })
            //   await localStorage.setItem("productDetails", JSON.stringify(productDetails));
  
            //   console.log(this.state.productDetails)
            // }
            
          }
          if(datas.quantity == 0){
            productDetails.splice(check2, 1)
          }
          else{
            productDetails.push({
                  product_id: datas._id,
                  quantity: datas.quantity,
                  size: "",
                  name: datas.name,
                  totalPrice: datas.quantity * datas.price,
                })
          }
          // else {
          //   productDetails.push({
          //     product_id: datas._id,
          //     quantity: datas.quantity,
          //     size: "",
          //     name: datas.name,
          //     totalPrice: datas.quantity * datas.price,
          //   })
          // }

          console.log(productDetails)

          this.setState({
            productDetails: productDetails
          })
          this.setState({
            productTotal: this.state.productTotal - (parseFloat(datas.price))
          })
          await localStorage.setItem("productDetails", JSON.stringify(productDetails));

          console.log(this.state.productDetails)
        }
        else if (subBusinessCategory[check].category_type == "service") {
          debugger
          let serviceDetails = [...this.state.serviceDetails];

          let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)

          // if (check2 > -1) serviceDetails.splice(check2, 1)



          // serviceDetails.push(datas)
          // if (size_price) {
          //   serviceDetails.push({
          //     service_id: datas._id,
          //     duration: datas.duration,
          //     name: datas.name,
          //     quantity: 1,
          //     size: size_price,
          //     totalPrice: datas.price
          //   })
          // }
          if (size_price.length > 0) {
            let serviceDetails = [...this.state.serviceDetails];
            
            let check2 = serviceDetails.findIndex(item => item.service_id == datas._id)
            if(check2 > -1){
              let is_chek_present = false;
              let i = 0 
              for (let index = 0; index < serviceDetails.length; index++) {
                const element = serviceDetails[index];
                if(element.size == size_price[0].size){
                  is_chek_present = true
                  i = index
                }
              }
              if(is_chek_present){
                serviceDetails[i].quantity = serviceDetails[i].quantity - 1
                if(serviceDetails[i].quantity == 0){
                  serviceDetails.splice(i, 1)
                }
              }
              console.log(serviceDetails)
              this.setState({
                productTotal: this.state.productTotal + (parseFloat(datas.price))
              })
              this.setState({
                serviceDetails: serviceDetails
              })
              await localStorage.setItem("serviceDetails", JSON.stringify(serviceDetails));
  
              console.log(this.state.serviceDetails)
            }
          } 
          
          console.log(this.state.serviceDetails)
        }
        this.openModel(datas,data, type, _id, category_id, add_data)
      }
    }

  }

  //ending above for extra Size option


  async increment_extra(event, category_id, extra_product_id, type, index, product_id, e_size_price) {
    // debugger
    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info

      let check1 = index_subBusinessCategory.findIndex(item => item._id == product_id)
      // console.log(event.target.checked, '===============', category_id, extra_product_id, type, index, product_id, e_size_price.check, 'kokoko')
      if (check1 > -1) {

        let extraInfo = subBusinessCategory[check].info[check1].extraInfo.extraInfoListData
        let check3 = extraInfo.findIndex(item => item._id == extra_product_id)

        subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].check = !subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].check

        if (subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].check == undefined) {
          this.setState({
            itemTotal: parseFloat(parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)) + this.state.itemTotal,
          })
          if (type == "product") {
             let prodDetails = this.state.productDetails;
             let pp = prodDetails.filter(item=>item.product_id ==product_id);
             let prodSize = pp[0].size?pp[0].size:''
            //  console.log(prodDetails,'kokokko=======kokokok',pp)
             let productSize = prodDetails.size
            let getextraProductDetails = [...this.state.extraProductDetails];
            if (event.target.checked) {
              //this one is cheked so adding to extraproductDetails
              if (getextraProductDetails.length > 0) {
                //geting for same product and extra product
                let dd = getextraProductDetails.findIndex(item => item.extra_product_id == extra_product_id && item.product_id == product_id);

                if (dd > -1) {
                  getextraProductDetails[dd].sizeArray.push({ size: e_size_price.size, price: e_size_price.price })
                  let priceTotal = getextraProductDetails[dd].sizeArray.reduce((a, b) => Number(a) + Number(b.price), 0);
                  getextraProductDetails[dd].totalPrice = priceTotal
                  console.log('same same ',getextraProductDetails[dd],priceTotal)
                  this.setState({
                    extraProductDetails: getextraProductDetails
                  })
                  await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

                } else {

                // for different product or extraproduct id
                  let sizeAr = []
                  let sizeParams = { size: e_size_price.size, price: e_size_price.price }
                  sizeAr = [...sizeAr, sizeParams]

                  getextraProductDetails.push({
                    extra_product_id: extra_product_id,
                    product_id: product_id,
                    quantity: 1,
                    sizeArray: sizeAr,
                    totalPrice: Number(e_size_price.price),
                    productSize:prodSize
                  })

                  this.setState({
                    extraProductDetails: getextraProductDetails
                  })
                  await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));
                }
              } else {
                // first time inserting data into extraProductDetails
                let sizeAr = []
                let sizeParams = { size: e_size_price.size, price: e_size_price.price }
                sizeAr = [...sizeAr, sizeParams]

                getextraProductDetails.push({
                  extra_product_id: extra_product_id,
                  product_id: product_id,
                  quantity: 1,
                  sizeArray: sizeAr,
                  totalPrice: Number(e_size_price.price),
                  productSize:prodSize
                })
                this.setState({
                  extraProductDetails: getextraProductDetails
                })
                await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));
              }
              console.log("checked")

            } else {
              // This on for uncheked that mean need to remove data from extraProductDetail
              console.log("UNchecked",getextraProductDetails)
              if (getextraProductDetails.length > 0){
                let dd = getextraProductDetails.findIndex(item => item.extra_product_id == extra_product_id && item.product_id == product_id);
                if(dd > -1){
                  let tt = getextraProductDetails[dd].sizeArray.findIndex(item => item.size == e_size_price.size);
                  if(getextraProductDetails[dd].sizeArray.length == 1){
                    getextraProductDetails.splice(dd,1)
                  }else{
                    getextraProductDetails[dd].sizeArray.splice(tt, 1);
                    getextraProductDetails[dd].totalPrice = Number(getextraProductDetails[dd].totalPrice) - Number(e_size_price.price)
                  }
                 
                  this.setState({
                    extraProductDetails: getextraProductDetails
                  })
                  await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));
                  console.log(tt,'challl jayega',getextraProductDetails)
                }else{
                  let bd = getextraProductDetails.findIndex(item => item.product_id == product_id);
                  if(bd >-1 ){
                    getextraProductDetails.splice(bd,1);
                    this.setState({
                      extraProductDetails: getextraProductDetails
                    })
                    await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));
                    console.log(bd,'challl jayega ===============',getextraProductDetails)
                  }
                }
              }
            }
            // let check2 = getextraProductDetails.findIndex(item=>item.extra_product_id == extra_product_id)
            // console.log(check2)
            // if(check2 > -1) {
            //   let getdata = getextraProductDetails[check2].sizeArray

            //   let getIndexSize = getdata.findIndex(item=>item.size == subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size)
            //   if(getIndexSize == -1){
            //     let addSizeArray = {
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price:parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //     }
            //     getdata.push(addSizeArray)
            //     getextraProductDetails[check2].sizeArray = getdata

            //     // getextraProductDetails[check].totalPrice = parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price) + parseInt(getextraProductDetails[check].totalPrice)

            //     this.setState({
            //       extraProductDetails:getextraProductDetails
            //     })
            //     await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

            //   }
            //   else{
            //     getdata.splice(getIndexSize, 1);

            //     getextraProductDetails[check2].sizeArray = getdata
            //     if(getextraProductDetails[check2].sizeArray == 1){
            //       getextraProductDetails = []
            //     }
            //     this.setState({
            //       extraProductDetails:getextraProductDetails
            //     })
            //     await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

            //   }
            // }else{
            //   let getextraProductDetails = [...this.state.extraProductDetails];
            //   let extraProductDetails = {
            //     extra_product_id:extra_product_id,
            //     product_id:product_id,
            //     sizeArray:[{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price:parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //     }],
            //     quantity:1,
            //     totalPrice:parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //   }
            //   getextraProductDetails.push(extraProductDetails)
            //   this.setState({
            //     extraProductDetails:getextraProductDetails
            //   })
            //   await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

            // }

          }
          else if (type == "service") {
            let getextraServiceDetails = [...this.state.extraServiceDetails];
            let check2 = getextraServiceDetails.findIndex(item => item.extra_service_id == extra_product_id)
            console.log(check2)
            console.log(event.target.checked, '===============', category_id, extra_product_id, type, index, product_id, e_size_price, 'kokoko')
            let serDetails = this.state.serviceDetails;
            let pp = serDetails.filter(item=>item.service_id ==product_id);
            let serSize = pp[0].size?pp[0].size:''
            if (event.target.checked) {
              //this one is cheked so adding to extraproductDetails
              if (getextraServiceDetails.length > 0) {
                //geting for same product and extra product
                let dd = getextraServiceDetails.findIndex(item => item.extra_service_id == extra_product_id && item.service_id == product_id);

                if (dd > -1) {
                  getextraServiceDetails[dd].sizeArray.push({ size: e_size_price.size, price: e_size_price.price ,duration:e_size_price.duration})
                  let priceTotal = getextraServiceDetails[dd].sizeArray.reduce((a, b) => Number(a) + Number(b.price), 0);
                  let totalDuration = getextraServiceDetails[dd].sizeArray.reduce((a, b) => Number(a) + Number(b.duration), 0);
                  getextraServiceDetails[dd].totalPrice = priceTotal
                  getextraServiceDetails[dd].duration = totalDuration
                  console.log('same same ',getextraServiceDetails[dd],priceTotal)
                  this.setState({
                          extraServiceDetails: getextraServiceDetails
                        })
                        await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));

                } else {

                // for different product or extraproduct id
                  let sizeAr = []
                  let sizeParams = { size: e_size_price.size, price: e_size_price.price,duration:e_size_price.duration }
                  sizeAr = [...sizeAr, sizeParams]

                  getextraServiceDetails.push({
                    extra_service_id: extra_product_id,
                    service_id: product_id,
                    sizeArray: sizeAr,
                    duration: Number(e_size_price.duration),
                    totalPrice: Number(e_size_price.price),
                    serviceSize:serSize
                  })

                  this.setState({
                    extraServiceDetails: getextraServiceDetails
                  })
                  await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));
                }
              } else {
                // first time inserting data into extraProductDetails
                let sizeAr = []
                  let sizeParams = { size: e_size_price.size, price: e_size_price.price,duration:e_size_price.duration }
                  sizeAr = [...sizeAr, sizeParams]

                  getextraServiceDetails.push({
                    extra_service_id: extra_product_id,
                    service_id: product_id,
                    sizeArray: sizeAr,
                    duration: Number(e_size_price.duration),
                    totalPrice: Number(e_size_price.price),
                    serviceSize:serSize
                  })
                  this.setState({
                    extraServiceDetails: getextraServiceDetails
                  })
                  await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));
              }
              console.log("checked")

            } else {
              // This on for uncheked that mean need to remove data from extraProductDetail
              console.log("UNchecked",getextraServiceDetails)
              if (getextraServiceDetails.length > 0){
                let dd = getextraServiceDetails.findIndex(item => item.extra_service_id == extra_product_id && item.service_id == product_id);
                if(dd > -1){
                  let tt = getextraServiceDetails[dd].sizeArray.findIndex(item => item.size == e_size_price.size);
                  if(getextraServiceDetails[dd].sizeArray.length == 1){
                    getextraServiceDetails.splice(dd,1)
                  }else{
                    getextraServiceDetails[dd].sizeArray.splice(tt, 1);
                    getextraServiceDetails[dd].totalPrice = Number(getextraServiceDetails[dd].totalPrice) - Number(e_size_price.price)
                    getextraServiceDetails[dd].duration = Number(getextraServiceDetails[dd].duration) - Number(e_size_price.duration)
                  }
                 
                  this.setState({
                    extraServiceDetails: getextraServiceDetails
                  })
                  await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));
                  
                }else{
                  let bd = getextraServiceDetails.findIndex(item => item.product_id == product_id);
                  if(bd >-1 ){
                    getextraServiceDetails.splice(bd,1);
                    this.setState({
                      extraServiceDetails: getextraServiceDetails
                    })
                    await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));
                  }
                }
              }
            }
            // if (check2 > -1) {
            //   let getdata = getextraServiceDetails[check2].sizeArray

            //   let getIndexSize = getdata.findIndex(item => item.size == subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size)
            //   if (getIndexSize == -1) {
            //     let addSizeArray = {
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //     }
            //     getdata.push(addSizeArray)
            //     getextraServiceDetails[check2].sizeArray = getdata

            //     // getextraServiceDetails[check].totalPrice = parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price) + parseInt(getextraServiceDetails[check].totalPrice)




            //     this.setState({
            //       extraServiceDetails: getextraServiceDetails
            //     })
            //     await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));

            //   }
            //   else {
            //     getdata.splice(getIndexSize, 1);

            //     getextraServiceDetails[check2].sizeArray = getdata

            //     this.setState({
            //       extraServiceDetails: getextraServiceDetails
            //     })
            //     await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));

            //   }
            // }
            // else {
            //   let getextraServiceDetails = [...this.state.extraServiceDetails];
            //   let extraServiceDetails = {
            //     extra_service_id: extra_product_id,
            //     service_id: product_id,
            //     sizeArray: [{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //     }],
            //     quantity: 1,
            //     duration: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].duration,
            //     totalPrice: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //   }
            //   getextraServiceDetails.push(extraServiceDetails)
            //   this.setState({
            //     extraServiceDetails: getextraServiceDetails
            //   })
            //   await localStorage.setItem("extraServiceDetails", JSON.stringify(extraServiceDetails));

            // }

          }
        }
        else {
          this.setState({
            itemTotal: this.state.itemTotal - parseFloat(parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price))
          })
        }
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
      }
    }


  }
  async increment_extra_radio(event,category_id, extra_product_id, type, index, product_id,e_size_price) {

    let subBusinessCategory = JSON.parse(localStorage.getItem('subBusinessCategory'));
    let check = subBusinessCategory.findIndex(item => item._id == category_id)

    if (check > -1) {

      let index_subBusinessCategory = subBusinessCategory[check].info
      let check1 = index_subBusinessCategory.findIndex(item => item._id == product_id)
      if (check1 > -1) {

        let extraInfo = subBusinessCategory[check].info[check1].extraInfo.extraInfoListData
        let check3 = extraInfo.findIndex(item => item._id == extra_product_id)

        subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].check = !subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].check

        if (subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].check == undefined) {
          this.setState({
            itemTotal: parseFloat(parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)) + this.state.itemTotal,
          })
          if (type == "product") {
            let getextraProductDetails = [...this.state.extraProductDetails];
            let prodDetails = this.state.productDetails;
             let pp = prodDetails.filter(item=>item.product_id ==product_id);
             let prodSize = pp[0].size?pp[0].size:''
            let dd = getextraProductDetails.findIndex(item => item.extra_product_id == extra_product_id && item.product_id == product_id);
            if(dd>-1){
              getextraProductDetails.splice(dd,1);
            }
              let sizeAr = []
              let sizeParams = { size: e_size_price.size, price: e_size_price.price }
              sizeAr = [...sizeAr, sizeParams]

              getextraProductDetails.push({
                extra_product_id: extra_product_id,
                product_id: product_id,
                quantity: 1,
                sizeArray: sizeAr,
                totalPrice: Number(e_size_price.price),
                productSize:prodSize
              })

              this.setState({
                extraProductDetails: getextraProductDetails
              })
              await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));


            
            // let check2 = getextraProductDetails.findIndex(item => item.extra_product_id == extra_product_id)
            // console.log(check2)
            // if (check2 > -1) {
            //   let getdata = getextraProductDetails[check2].sizeArray

            //   let getIndexSize = getdata.findIndex(item => item.size == subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size)
            //   if (getIndexSize == -1) {
            //     let addSizeArray = [{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //     }]
            //     getextraProductDetails[check2].sizeArray = addSizeArray

            //     // getextraProductDetails[check].totalPrice = parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price) + parseInt(getextraProductDetails[check].totalPrice)

            //     this.setState({
            //       extraProductDetails: getextraProductDetails
            //     })
            //     await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

            //   }

            // } else {
            //   let getextraProductDetails = [...this.state.extraProductDetails];
            //   let extraProductDetails = {
            //     extra_product_id: extra_product_id,
            //     product_id: product_id,
            //     sizeArray: [{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //     }],
            //     quantity: 1,
            //     totalPrice: parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price)
            //   }
            //   getextraProductDetails.push(extraProductDetails)
            //   this.setState({
            //     extraProductDetails: getextraProductDetails
            //   })
            //   await localStorage.setItem("extraProductDetails", JSON.stringify(getextraProductDetails));

            // }

          }
          else if (type == "service") {
            let getextraServiceDetails = [...this.state.extraServiceDetails];
            let serDetails = this.state.serviceDetails;
            let pp = serDetails.filter(item=>item.service_id ==product_id);
            let serSize = pp[0].size?pp[0].size:''
            
            let dd = getextraServiceDetails.findIndex(item => item.extra_service_id == extra_product_id && item.service_id == product_id);
            if(dd>-1){
              getextraServiceDetails.splice(dd,1);
            }
              let sizeAr = []
              let sizeParams = { size: e_size_price.size, price: e_size_price.price,duration:e_size_price.duration }
                  sizeAr = [...sizeAr, sizeParams]

                  getextraServiceDetails.push({
                    extra_service_id: extra_product_id,
                    service_id: product_id,
                    sizeArray: sizeAr,
                    duration: Number(e_size_price.duration),
                    totalPrice: Number(e_size_price.price),
                    serviceSize:serSize
                  })
                  this.setState({
                    extraServiceDetails: getextraServiceDetails
                  })
                  await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));
            // if (check2 > -1) {
            //   let getdata = getextraServiceDetails[check2].sizeArray

            //   let getIndexSize = getdata.findIndex(item => item.size == subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size)
            //   if (getIndexSize == -1) {
            //     let addSizeArray = [{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //     }]

            //     getextraServiceDetails[check2].sizeArray = addSizeArray

            //     // getextraServiceDetails[check].totalPrice = parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price) + parseInt(getextraServiceDetails[check].totalPrice)




            //     this.setState({
            //       extraServiceDetails: getextraServiceDetails
            //     })
            //     await localStorage.setItem("extraServiceDetails", JSON.stringify(getextraServiceDetails));

            //   }

            // }
            // else {
            //   let getextraServiceDetails = [...this.state.extraServiceDetails];
            //   let extraServiceDetails = {
            //     extra_service_id: extra_product_id,
            //     service_id: product_id,
            //     sizeArray: [{
            //       size: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].size,
            //       price: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //     }],
            //     quantity: 1,
            //     duration: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].duration,
            //     totalPrice: subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price
            //   }
            //   getextraServiceDetails.push(extraServiceDetails)
            //   this.setState({
            //     extraServiceDetails: getextraServiceDetails
            //   })
            //   await localStorage.setItem("extraServiceDetails", JSON.stringify(extraServiceDetails));

            // }

          }
        }
        else {
          this.setState({
            itemTotal: this.state.itemTotal - parseFloat(parseInt(subBusinessCategory[check].info[check1].extraInfo.extraInfoListData[check3].dataList[index].price))
          })
        }
        await localStorage.setItem("subBusinessCategory", JSON.stringify(subBusinessCategory));
      }
    }


  }
  async openModelextra(category_id, data, type, data_id, _id,info_data) {
    // return 

    console.log(_id, 'okkokook')
    {console.log(info_data,'in======fo========')}


    let multiselect = <div></div>

    let nonmultiselect = <div></div>;
    let i = 0
    // return 
    nonmultiselect = await data.map(ele => {
      console.log(ele.multi_selected)

      if (ele.multi_selected) {

        let checkRadio = "checkRadio" + ele._id + i++
        return <div>
          <div className="mt-3">
            <h5 style={{ color: "green" }}>
              {ele.name}
            </h5>
          </div>
          {ele.dataList.map(e_size_price => {
            console.log(e_size_price.check, 'kya aa rha hai')

            return <div className="d-flex align-items-center mt-3">

              <input
                type="checkbox"
                id={checkRadio}
                style={{ marginRight: "10px" }}
                name={checkRadio}
                checked={e_size_price.check}
                onChange={(e) => this.increment_extra(e, category_id, ele._id, type, e_size_price.index, _id, e_size_price, e_size_price.check)}
                aria-label="Checkbox for following text input"
              />
              <label
                className="mr-2"
                style={{ marginRight: "10px" }}
                for={checkRadio}
              >
                <h5>{e_size_price.size}</h5>
              </label>
              <div>
                <h6>${e_size_price.price}</h6>
              </div>
            </div>
          })}
        </div>
      }
      else {
        let checkRadio = "checkRadio" + ele._id + i++
        return <div>
          <div className="mt-3">
            <h5 style={{ color: "green" }}>
              {ele.name}
            </h5>


          </div>
          {ele.dataList.map(e_size_price => {
            // let index = "customCheck"+e_size_price.index+_id

            // let checkRadio = "checkRadio"+ele.index+ele._id

            return <div className="d-flex align-items-center mt-3">

              <input
                type="radio"
                style={{ marginRight: "10px" }}
                name={checkRadio}
                id={checkRadio}
                onChange={(e) => this.increment_extra_radio(e,category_id, ele._id, type, e_size_price.index, _id,e_size_price)}
                checked={e_size_price.check}
                aria-label="Radio button for following text input"
              />
              <label
                className="mr-2"
                style={{ marginRight: "10px" }}
                for={checkRadio}
              >
                <h5>{e_size_price.size}</h5>
              </label>
              <div>
                <h6>${e_size_price.price}</h6>
              </div>
            </div>
          })}
        </div>
      }



    });
    // return
    await this.setState({
      modalData1:
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              {/* <div className="d-flex justify-content-between ">
              <div>
                <h3>Food Name</h3>
              </div>
              <div>
                <h3>$4.5</h3>
              </div>
            </div> */}
              {nonmultiselect}
              <div className="d-flex justify-content-between modeldiv mt-3">
                <div>
                  <h5>Items Total {this.state.itemTotal}</h5>
                </div>
                <div style={{ cursor: "pointer" }}>
                  <h5>Add Item</h5>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={(e) => this.onClose()}
              >
                Close
              </button>
              <button type="button" class="btn btn-primary"
                data-dismiss="modal"
                onClick={(e) => this.onClose()}>
                Save changes
              </button>
            </div>
          </div>
        </div>
    })
  }

  orderNow() {

    if (!this.state.token) {
      toast.warning('Please Login to proceed', { autoClose: 1000 })
    } else {

    if (!this.state.serviceDetails.length) {
      toast.warning('Please Select a Service to proceed', { autoClose: 1000 })

    } else {
      sessionStorage.setItem("is_clicked", true)
      sessionStorage.setItem("order_type", "productAndService")
      sessionStorage.setItem("now_later", "now")

      this.props.createOrder({
        bussinessId: "62af76f40486972934427b8c",
        professionalId: localStorage.getItem('professionalId'),
        locality: 'local',
        order_type: sessionStorage.getItem('order_type'),
        time_zone: sessionStorage.getItem('time_zone'),
        req_service_date: new Date(),
        req_service_time: new Date(),
        productDetails: JSON.parse(localStorage.getItem('productDetails')),
        serviceDetails: JSON.parse(localStorage.getItem('serviceDetails')),
        extraProductDetails: JSON.parse(localStorage.getItem('extraProductDetails')) ? JSON.parse(localStorage.getItem('extraProductDetails')) : "",
        extraServiceDetails: JSON.parse(localStorage.getItem('extraServiceDetails')) ? JSON.parse(localStorage.getItem('extraServiceDetails')) : ""
      })
      sessionStorage.setItem('is_clicked', false)
      this.props.history.push("/servicelocation");
    }
  }
  }

  orderLater() {
    if (!this.state.token) {
      toast.warning('Please Login to proceed', { autoClose: 1000 })
    } else {
    if (!this.state.serviceDetails.length) {
      toast.warning('Please Select a Service to proceed', { autoClose: 1000 })

    } else {
      sessionStorage.setItem("is_clicked", true)
      sessionStorage.setItem("order_type", "productAndService")
      sessionStorage.setItem("now_later", "later")

      this.props.createOrder({
        bussinessId: "62af76f40486972934427b8c",
        professionalId: localStorage.getItem('professionalId'),
        locality: 'local',
        order_type: sessionStorage.getItem('order_type'),
        time_zone: localStorage.getItem('timezone'),
        req_service_date: new Date(),
        req_service_time: new Date(),
        productDetails: JSON.parse(localStorage.getItem('productDetails')),
        serviceDetails: JSON.parse(localStorage.getItem('serviceDetails')),
        extraProductDetails: JSON.parse(localStorage.getItem('extraProductDetails')) ? JSON.parse(localStorage.getItem('extraProductDetails')) : "",
        extraServiceDetails: JSON.parse(localStorage.getItem('extraServiceDetails')) ? JSON.parse(localStorage.getItem('extraServiceDetails')) : ""
      })
      this.props.getTimeSlot({
        professionalId: localStorage.getItem('professionalId'),
        requestedDate: moment().tz(localStorage.getItem('timezone')).format('YYYY-MM-DD'),
        time_zone: localStorage.getItem('timezone')
      })
      sessionStorage.setItem('is_clicked', false)
      this.props.history.push("/servicelocation"); this.props.history.push("/servicelocation");
    }
  }
  }
  render() {
    let i = 0
    let subBusinessCategory = [];
    let product = [];
    let subBusinessCategory_data = JSON.parse(localStorage.getItem('subBusinessCategory'));
    // console.log(subBusinessCategory_data)
    {
      subBusinessCategory_data.length > 0 ?
      subBusinessCategory_data.map(datas => {
        let category_type = "home-tab " + datas._id
        if (i == 0 && this.state.is_first) {
          subBusinessCategory.push(
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="profile-tab"//{category_type}
                data-toggle="tab"
                href="#exampleModal7"
                role="tab"
                aria-controls="exampleModal7"
                aria-selected="true"
                onClick={(e) => this.change(datas._id, datas.category_type, datas.info)}
              >
                {datas.category_name}
              </a>
            </li>
          );

          if (datas.info && datas.info.length > 0) {
            let info = datas.info;
            info.length > 0 ?
              info.map(info_data => {
                var category_type_options = datas.category_type == "service" ? "service" : "product"
                product.push(
                  <div className="d-flex align-items-center mt-3">
                    <img src={info_data.image == null ? info_data.image : info_data.image} style={{width: "200px",height:'200px',objectFit:'contain'}} />

                    <div className="carpoolContent ">
                      <h4>{info_data.name == null ? info_data.name : info_data.name}</h4>
                      <p>{info_data.description}</p>
                      <h3>${info_data.price}</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      {
                        datas.category_type == "product" ?
                          (info_data.size_price == undefined || info_data.size_price.length == 0 ?
                            !info_data.quantity ?
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  ADD
                                </button>
                              </div> :
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.decrement(info_data, [])}
                                >
                                  &mdash;
                                </button>
                                {info_data.quantity}

                                <button className="btn btn-primary ml-3--right"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  &#xff0b;
                                </button>
                              </div>


                            :
                            <button
                              type="button"
                              class="btn btn-primary ml-3"
                              data-toggle="modal"
                              data-target="#exampleModal7"
                              onClick={(e) => this.openModel(info_data,info_data.size_price, category_type_options, info_data._id, datas._id, 1)}
                            >
                              Get Product Size
                            </button>) :
                          info_data.size_price_duration == undefined || info_data.size_price_duration.length == 0 ?
                            !info_data.quantity ?
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  ADD
                                </button>
                              </div> :
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.decrement(info_data, [])}
                                >
                                  &mdash;
                                </button>
                                {info_data.quantity}

                                <button className="btn btn-primary ml-3--right"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  &#xff0b;
                                </button>
                              </div>


                            :
                            <button
                              type="button"
                              class="btn btn-primary ml-3"
                              data-toggle="modal"
                              data-target="#exampleModal7"
                              onClick={(e) => this.openModel(info_data,info_data.size_price_duration, category_type_options, info_data._id, datas._id, 1)}
                            >
                              Get Service Size
                            </button>

                      }
                      {
                        !info_data.quantity ? <div></div> :
                          info_data.extraInfo.extraInfoListData.length == 0 ? <div></div> :
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal8"
                              onClick={(e) => this.openModelextra(info_data.category_id, info_data.extraInfo.extraInfoListData, category_type_options, datas._id, info_data._id,info_data)}

                            >
                              {datas.category_type == "service" ? "Get extra service" : "Get extra product"}
                            </button>
                      }

                    </div>
                  </div>
                )
              }) : <div></div>

          }


          i++;

        }
        else {
          subBusinessCategory.push(
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="profile-tab "//{category_type}
                data-toggle="tab"
                href="#exampleModal7"
                role="tab"
                aria-controls="exampleModal7"
                aria-selected="false"
                onClick={(e) => this.change(datas._id, datas.category_type, datas.info)}
              >
                {datas.category_name}
              </a>
            </li>
          )
          if (datas.info && datas.info.length > 0 && datas._id == this.state.id) {
            let info = datas.info;
            var category_type_options = datas.category_type == "service" ? "service" : "product"
            info.length > 0 ?
              info.map(info_data => {
                product.push(
                  <div className="d-flex align-items-center mt-3">
                    <img src={info_data.image == null ? info_data.image : info_data.image} style={{width: "200px",height:'200px',objectFit:'contain'}} />

                    <div className="carpoolContent ">
                      <h4>{info_data.name == null ? info_data.name : info_data.name}</h4>
                      <p>{info_data.description}</p>
                      <h3>${info_data.price}</h3>
                    </div>

                    <div className="col d-flex justify-content-end ">
                      {
                        datas.category_type == "product" ?
                          (info_data.size_price == undefined || info_data.size_price.length == 0 ?
                            !info_data.quantity ?
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  ADD
                                </button>
                              </div> :
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.decrement(info_data, [])}
                                >
                                  &mdash;
                                </button>
                                {info_data.quantity}

                                <button className="btn btn-primary ml-3--right"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  &#xff0b;
                                </button>
                              </div>


                            :
                            <button
                              type="button"
                              class="btn btn-primary ml-3"
                              data-toggle="modal"
                              data-target="#exampleModal7"
                              onClick={(e) => this.openModel(info_data,info_data.size_price, category_type_options, info_data._id, datas._id, 1)}
                            >
                              Get Product Size
                            </button>) :
                          info_data.size_price_duration == undefined || info_data.size_price_duration.length == 0 ?
                            !info_data.quantity ?
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  ADD
                                </button>
                              </div> :
                              <div className="quantity-input">
                                <button className="btn btn-primary ml-3--left"
                                  onClick={(e) => this.decrement(info_data, [])}
                                >
                                  &mdash;
                                </button>
                                {info_data.quantity}

                                <button className="btn btn-primary ml-3--right"
                                  onClick={(e) => this.increment(info_data, [])}
                                >
                                  &#xff0b;
                                </button>
                              </div>


                            :
                            <button
                              type="button"
                              class="btn btn-primary ml-3"
                              data-toggle="modal"
                              data-target="#exampleModal7"
                              onClick={(e) => this.openModel(info_data,info_data.size_price_duration, category_type_options, info_data._id, datas._id, 1)}
                            >
                              Get Service Size
                            </button>

                      }
                      {
                        !info_data.quantity ? <div></div> :
                          info_data.extraInfo.extraInfoListData.length == 0 ? <div></div> :
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal8"
                              onClick={(e) => this.openModelextra(info_data.category_id, info_data.extraInfo.extraInfoListData, category_type_options, datas._id, info_data._id)}

                            >
                              {datas.category_type == "service" ? "Get extra service" : "Get extra product"}
                            </button>
                      }

                    </div>
                  </div>
                )
              }) : <div></div>

          }

          i++;
        }
      })

      : <div>No data Here</div>
    }
    const modalData1 = this.state.modalData1
    const modalData = this.state.modalData
    return (
      <>
        <Header />

        <section className="mt-5 mb-5">
          <div className="container">
            <div className="shadowCont mt-4">
              {/* <h5>Product and service Total {(this.state.productTotal).toFixed(2)}</h5> */}
              <h5>Product and service Total {this.state.currencySymbol} {
              this.state.extraProductDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)
              +
              this.state.extraServiceDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)
              +
              this.state.productDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)
              +
              this.state.serviceDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0)
           }
           </h5>

              <h5>Product Sum {((this.state.productDetails).reduce((a, b) => Number(a) + Number(b.totalPrice), 0)).toFixed(2)}</h5>
              <h5>Service Sum {((this.state.serviceDetails).reduce((a, b) => Number(a) + Number(b.totalPrice), 0)).toFixed(2)}</h5>
              <h5>Products {this.state.productDetails.length}</h5>
              <h5>Service {this.state.serviceDetails.length}</h5>
              <h5>Extra Product Sum {this.state.currencySymbol} {this.state.extraProductDetails.length?this.state.extraProductDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0):0}</h5>
              <h5>Extra Service Sum {this.state.currencySymbol} {this.state.extraServiceDetails.length?this.state.extraServiceDetails.reduce((a, b) => Number(a) + Number(b.totalPrice), 0):0}</h5>
              {/* <Link to="/servicelocation"> */}
              <button
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                onClick={() => this.orderNow()}
              >
                Order Now
              </button>
              {/* </Link> */}
              {/* <Link to="/servicelocation"> */}

              <button
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                onClick={
                  () => this.orderLater()
                }
              >
                Order Later
              </button>
              {/* </Link> */}
              <div className="p-4">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  {subBusinessCategory}
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {product}
                  </div>
                </div>
                <div
                  class="modal fade"
                  id="exampleModal7"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body">
                        <div className="d-flex justify-content-between ">
                          <div>
                            <h3>Select Size</h3>
                          </div>
                        </div>
                        {modalData}

                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="button" class="btn btn-primary">
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal fade"
                  id="exampleModal8"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  {modalData1}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    loading: state.subBusiness.loading,
    subBusinesslisting: state.subBusiness.subBusinesslisting,
    subBusinessCategory: state.subBusiness.subBusinessCategory,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchsubBusiness: (data) => dispatch(actions.fetchsubBusinessListAction(data)),
    fetchgetextraproductList: (data) => dispatch(actions.fetchgetextraproductListAction(data)),
    fetchgetextraserviceList: (data) => dispatch(actions.fetchgetextraserviceListAction(data)),
    fetchgetproductAction: (data) => dispatch(actions.fetchgetproduct(data)),
    fetchgetServiceAction: (data) => dispatch(actions.fetchgetService(data)),
    getDataCategoryAction: (data) => dispatch(actions.getDataCategory(data)),
    createOrder: (orderData) => dispatch(actions.createOrder(orderData)),
    getTimeSlot: (data) => dispatch(actions.getTimeSlot(data))
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(homepage));