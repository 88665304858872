import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  timeSlot:{}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TIME_SLOT:
        sessionStorage.setItem("timeSlots", JSON.stringify(action.timeSlot))
      return {
        ...state,
        loading:null,
        timeSlot:action.timeSlot,
        error:null,
        success:action.message
      };
    case actionTypes.FAIL_TIME_SLOT :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    default:
      return state;
  }
};

export default reducer;
